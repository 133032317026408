import { get, post,patch,deleted } from 'app/apis';
import JobOrderRoutes from './JobOrder.Routes';

const JobOrderServices = {
  getJobOrderDetails: async (id) => {
    const data = await get(JobOrderRoutes.getJobOrderDetails+`${id}`);
    return data;
  },
  getJobOrderForEditById: async (id) => {
    const data = await get(JobOrderRoutes.getJobOrderForEditById+`${id}`);
    return data;
  },
  getAllJobOrderDetails: async (obj) => {
    const data = await get(JobOrderRoutes.getAllJobOrderDetails+ `?limit=${obj?.limit}&page=${obj?.page}${obj?.status ? `&status=${obj?.status}` : ''}${obj?.customer_id ? `&customer_id=${obj?.customer_id}` : ''}`);
    return data;
  },
  getCheckListDetails: async (obj) => {
    const data = await get(JobOrderRoutes.getCheckListDetails+ `${obj.order_type}/${obj.order_id}`);
    return data;
  },
  postJobOrderDetails: async (obj) => {
    const data = await post(JobOrderRoutes.postJobOrderDetails,obj);
    return data;
  },
  updateJobOrder: async (id,obj) => {
    const data = await patch(JobOrderRoutes.updateJobOrder+`${id}`,obj);
    return data;
  },
  updateJobOrderStatus: async (id,obj) => {
    const data = await patch(JobOrderRoutes.updateJobOrderStatus+`${id}`,obj);
    return data;
  },
  deleteJobOrder: async (id,obj) => {
    const data = await deleted(JobOrderRoutes.deleteJobOrder+`${id}`);
    return data;
  },
  getVAT: async (id) => {
    const data = await get(JobOrderRoutes.getVAT);
    return data;
  },
  getTimeSlot: async (date) => {
    const data = await get(JobOrderRoutes.getTimeSlots+`?date=${date}`);
    return data;
  },
}

export default JobOrderServices