import React, { useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import CustomerServices from 'app/apis/Customer/CustomerServices';
import colors from 'app/styles/colors';


// Custom styles
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width:'100%',height:'56px',
    border: `1px solid #ccc`,
    borderColor:state.isFocused ? colors.secondaryDark : null,
    '&:hover': {
      border: state.isFocused ? null : `1px solid ${colors.secondaryDark} !important`,
      cursor:'pointer'
    },
    '& .css-1u9des2-indicatorSeparator':{
      display:'none'
    },
    boxShadow: state.isFocused ? `0 0 0 2px ${colors.secondaryDark}` : null,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? colors.springGreen : null,
    '&:hover': {
      backgroundColor:colors.springGreen,
    },
    color: state.isFocused ? 'white' : null,
    cursor:'pointer',
  }),
}

export const EntitySelectInput = ({
  countryID,
  isMulti = false,
  isDisabled,
  onChange,
  type = 'City',
  value,
  required_id = 'id',
  identifier = 'name',
  placeholder,
}) => {

  const loadOptions = async (search, o) => {
    // Filter for certain value by typing name
    if (search && search !== '') {
      search = `&search=${search}`;
    } else {
      search = '';
    }
    const {data,count} = await CustomerServices.getCitiesByCountryId({id:countryID,query:`${search}${`&page=${o.length >= 10 ? (o.length)/10 : 1}`}`})
    return {
      options: data?.map((d) => ({
        value: d[required_id],
        label: d[identifier]
      })),
      hasMore: count > o.length + 1
    };
  };

  return (
    <AsyncPaginate
      placeholder={placeholder || `Select ${type}`}
      isClearable
      isMulti={isMulti}
      value={value?.value ? value : null}
      loadOptions={loadOptions}
      onChange={onChange}
      styles={customStyles}
      isDisabled={isDisabled ? isDisabled : false}
    />
  );
};
