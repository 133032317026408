import {Fragment, useEffect, useState} from 'react';
import { Box, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { Add, MoreVert } from '@mui/icons-material';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { makeStyles } from '@mui/styles';
import StatusChip from 'app/components/StatusChip/StatusChip';
import Loader from 'app/components/Loader/Loader';
import { getCookie } from 'app/hooks/useCookie';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'order_number', label: 'Job Order Number', clickAble: true },
  {id: 'invoice_number', label: 'Invoice Number', clickAble: true },
  {id: 'customer_name',label: 'Customer',align: 'left',},
  {id: 'supervisor_name',label: 'Operation Supervisor',align: 'left',},
  {id: 'aircraft_engineer_name',label: 'Aircraft Engineer',align: 'left',},
  {id: 'start_date',label: 'Date',align: 'left',},
  {id: 'order_status',label: 'Order Status',align: 'left',},
];


function ViewListing() {
  const classes = useStyles();
  const userID = getCookie('userinfo')?.id
  const {status=''} = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState(status?.toLowerCase() === 'open' ? 'upcoming' : status?.toLowerCase() ? status : 'all');
  const [actionData, setActionData] = useState('');
  
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  // api data
  const [jobOrderData, setJobOrderData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *For Updating Job Order Status
  const updateJobOrderStatus = async (id,obj) => {
    try {
    const { status, responseCode, message } = await JobOrderServices.updateJobOrderStatus(id,obj)
    if (status === true && responseCode === 200) {
      getAllJobOrderDetails({limit:rowsPerPage,page:page,status:filter === 'upcoming' ? '1' : filter === 'pending' ? '2' : filter === 'all' ? '' : '3'})
      SuccessToaster(message)
      } 
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Service
  const getAllJobOrderDetails = async (obj) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,count } = await JobOrderServices.getAllJobOrderDetails(obj)
      if (status === true && responseCode === 200) {
        setJobOrderData(data);
        setCount(count);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAllJobOrderDetails({limit:rowsPerPage,page:page,status:filter === 'upcoming' ? '1':filter === 'pending' ? '2' : 
    filter === 'closed' ? '3' : ''})
  }, [filter, page, rowsPerPage]);

  return (
  <Layout>
    <Box>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Job Orders</Typography>
      <SecondaryButton title={"Create Job Order"}  onClick={() => navigate('/create-job-order')} startIcon={<Add />} />
      </Box>
      <BreadCrumbs currentPage={['Job Orders']} />
      <Box sx={{border:'1.5px solid #008035', borderRadius:'8px',height:'45px',display:'flex',alignItems:"center",maxWidth:'max-content',boxSizing:'border-box',my:4,}}>
        {['All','Upcoming','Pending','Closed'].map((item,index) => (
        <Box key={`Tabs-${index}`} sx={{borderRight:index !== 3 ?'1.5px solid #008035' : '',height:'100%',width:'100px',alignItems:'center',cursor:'pointer',
        display:'flex',backgroundColor:filter?.toLowerCase()=== item.toLowerCase() ? '#008035' : '',borderRadius:index === 0 ? '7px 0px 0px 7px' : index === 3 ? '0px 7px 7px 0px' :  ''}}
        onClick={() =>{ setFilter(item?.toLowerCase());setPage(1)}}>
        <Typography sx={{color:filter?.toLowerCase() !== item.toLowerCase() ? '#008035' : 'white', fontWeight:'bold',mx:'auto'}}>{item}</Typography></Box>
        ))}
      </Box>
      {isLoading ? <Loader /> :
      <Fragment>
        {jobOrderData?.length > 0 ? (
        <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
          <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
            <Table className={classes.table} stickyHeader aria-label="sticky table" >
              <TableHead  >
                <TableRow>
                  {columns.map((column) => {
                    if(column?.id=== 'invoice_number' && (filter === 'closed' || filter === 'all'))
                    {return (
                      <StyledTableCell key={column.id} align={column.align} style={{ fontWeight:600 }}>
                        {column.label}
                      </StyledTableCell>
                  )} else if(column?.id !== 'invoice_number'){
                    return(
                      <StyledTableCell key={column.id} align={column.align} style={{ fontWeight:600 }}>
                      {column.label}
                      </StyledTableCell>
                    )
                  }
                  })}
                  <StyledTableCell style={{ fontWeight:600 }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobOrderData?.map((row, index) => {
                  return(<TableRow hover role="checkbox" tabIndex={-1} key={`Row-${index}`}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={`Body-${column.id}`}>
                          {column.id === 'order_status' ? (
                            <StyledTableCell key={column.id} >
                              <StatusChip value={value?.toLowerCase() === 'open' ? 'Upcoming' : value} />
                            </StyledTableCell>
                          ) : column.id === 'invoice_number' ? (
                            <Fragment>
                            {(filter === 'closed' || filter === 'all') &&
                              <StyledTableCell key={column.id}  sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                              onClick={() =>  {column.clickAble && row?.invoice_id && 
                              navigate(`/view-job-order/${row?.invoice_id}/${row?.invoice_number}`)}}>{row?.invoice_number ? row?.invoice_number : '--'}
                              </StyledTableCell>}
                            </Fragment>  
                          ) : (
                          <StyledTableCell key={column.id}  sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                          onClick={() =>  {column.clickAble && navigate(`/view-job-order/${row.id}`)}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </StyledTableCell>)}
                          </Fragment>
                        );
                      })}
                      <StyledTableCell >
                        <MoreVert sx={{color:colors.primary, cursor:'pointer'}} onClick={(event) => {setAnchorEl(event.currentTarget);setActionData(row)}} />
                      </StyledTableCell>
                  </TableRow>)
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25,50 ,100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page -1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>) : 
        (<Box display={'flex'}
          p={6.25} alignItems={'center'}
          justifyContent={'center'}>
          <Typography variant="h5" fontWeight={400} >
            No Data
          </Typography>
        </Box>)}
      </Fragment>}
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{vertical: 'top',horizontal: 'left'}}
        transformOrigin={{vertical: 'top',horizontal: 'right'}}
        onClose={() => setAnchorEl(null)}
        PaperProps={{style: {width: '180px',borderRadius:'5px'}}}>
        <MenuItem onClick={() => {setAnchorEl(null);navigate(`${actionData?.id}`)}}>View</MenuItem>
        {actionData?.order_status?.toLowerCase() === 'pending' && +userID !== +actionData?.created_by &&
        <>
          <MenuItem onClick={() => {setAnchorEl(null);updateJobOrderStatus(actionData?.id,{status:1})}}>Accept</MenuItem>
          <MenuItem onClick={() => {setAnchorEl(null);updateJobOrderStatus(actionData?.id,{status:4})}}>Reject</MenuItem>
        </>}
      </Menu>
    </Box>
  </Layout>
  )
}

export default ViewListing