import { post, patch } from 'app/apis';
import AuthRoutes from './Auth.Routes';

const AuthServices = {
  login: async (obj) => {
    const data = await post(AuthRoutes.login, obj);
    return data;
  },
  register: async (obj) => {
    const data = await post(AuthRoutes.register, obj);
    return data;
  },
  sendVerificationCode: async (obj) => {
    const data = await post(AuthRoutes.sendVerificationCode, obj);
    return data;
  },
  changePassword: async (obj) => {
    const data = await patch(AuthRoutes.changePassword, obj);
    return data;
  },
  resetPassword: async (obj) => {
    const data = await patch(AuthRoutes.resetPassword, obj);
    return data;
  },
}

export default AuthServices