import { useState } from "react";
import { setCookie,getCookie,removeCookie } from '../hooks/useCookie'

function useProvideAuth() {
  const  userDetails = getCookie('userinfo')
  const [user, setUser] = useState(userDetails?.token);
  const [name, setName] = useState(userDetails?.name);
  const [email, setEmail] = useState(userDetails?.email);

  const login = (data) => {
    setUser(data.token)
    setName(data.name)
    setEmail(data.email);
    setCookie('userinfo',JSON.stringify(data));
  };

  const logout = () => {
    removeCookie('userinfo')
    setUser(null)
    setName(null)
    setEmail(null);
  };

  // *For Verify Token
  const verify = () => {
    const token = userDetails?.token
    const name = userDetails?.name
    const email = userDetails?.email
    setUser(token)
    setName(name)
    setEmail(email)
  };

  return {user,name,email,login,logout,verify,};
}

export default useProvideAuth;