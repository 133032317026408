import React from 'react';
import { ClearTwoTone } from '@mui/icons-material';
import { Box, Modal, Typography } from '@mui/material'
import colors from 'app/styles/colors';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const style = {
    position: 'absolute',top: '50%',left: '50%',
    transform: 'translate(-50%, -50%)',width: 700,
    backgroundColor: 'white',zIndex:999,
    boxShadow: 24,padding: 10,
  };

function ImageViewer({openImageViewer, setImageViewer, ImageList}) {
  return (
    <Modal
        open={openImageViewer}
        onClose={() => setImageViewer(false)}
      >
        <Box style={style}>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',mb:2}}>
          <Typography  variant="h6" color="primary" fontWeight={600}>Images Uploaded:</Typography>
          <ClearTwoTone sx={{cursor:'pointer', backgroundColor:colors.primaryLight, p:0.5, borderRadius:'5px',color:colors.primary}}
          onClick={() => setImageViewer(false)} />
        </Box>
        <Box >
        <Carousel showArrows={true} 
        // onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}
        >
        {ImageList?.map((file,index) => (
          <div key={index} style={{height:'500px',}}>
              <img style={{objectFit:'contain', height:'100%'}} src={file?.image} alt={file?.image} />
          </div>
          ))}
        </Carousel>
        </Box>
        </Box>
      </Modal>
  )
}

export default ImageViewer