// import EmployeeDetails from "app/views/Employee/EmployeeDetails";
import Login from "app/views/Auth/Login";
// import { Navigate } from "react-router-dom";

const AppRoutes = [
  {
    path: "*",
    component: <Login />,
  },
  
];

export default AppRoutes