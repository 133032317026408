import { get, post, patch, deleted } from 'app/apis';
import AircraftRoutes from './Aircraft.Routes';

const AircraftServices = {
  getAircraftServed: async (obj) => {
    const data = await get(AircraftRoutes.getAircraftServed+`?limit=${obj.limit}&page=${obj.page}${obj.aircraft_type_id ? `&aircraft_type_id=${obj.aircraft_type_id}` : ''}`);
    return data;
  },
  getAircraftTypes: async () => {
    const data = await get(AircraftRoutes.getAircraftTypes);
    return data;
  },
  postAircraft: async (obj) => {
    const data = await post(AircraftRoutes.postAircraft,obj);
    return data;
  },
  patchAircraftById: async (obj) => {
    const data = await patch(AircraftRoutes.patchAircraftById+`/${obj.id}`,obj.data);
    return data;
  },
  deleteAircraftById: async (id) => {
    const data = await deleted(AircraftRoutes.deleteAircraftById+`/${id}`);
    return data;
  },
  getFleetById: async (obj) => {
    const data = await get(AircraftRoutes.getFleetById+`${obj?.id}/${obj?.fleetId}`);
    return data;
  },
}

export default AircraftServices