import {  patch } from 'app/apis';
import MyProfileRoutes from './MyProfile.Routes';

const MyProfileServices = {
  updateProfile: async (obj) => {
    const data = await patch(MyProfileRoutes.updateProfile, obj);
    return data;
  },
}

export default MyProfileServices