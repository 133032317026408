import { FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  TextField,  Typography,  styled, tableCellClasses } from '@mui/material'
import { Error } from 'app/components/UI/Error';
import colors from 'app/styles/colors';
import { formatPrice } from 'app/utils/helper';
import React from 'react'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'white',
      color: colors.secondaryDark,
      fontSize:18, fontWeight:600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  

function PriceVariation({data=[], value,setValue,touched,errors,disabled}) {
  return (
    <TableContainer component={Paper} sx={{my:2}}>
      <Table sx={{ minWidth: 700,"& .MuiTableCell-root": {
      border: '1px solid #ddd',
    } }} >
        <TableHead>
          <StyledTableCell align="left" colSpan={3} style={{backgroundColor:'#eee'}}>Service Price Variation</StyledTableCell>
          <TableRow>
            <StyledTableCell align="center">Aircraft</StyledTableCell>
            <StyledTableCell align="center">Day Shift Price</StyledTableCell>
            <StyledTableCell align="center">Over Night Price</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row,index) => (
            <TableRow key={index}>
              <StyledTableCell align="center" >{row.aircraft_served_name}</StyledTableCell>
              <StyledTableCell align="center">
              <FormControl fullWidth>
                <TextField disabled={disabled} name={`price_variation[${index}].day_shift_price`} placeholder='Enter Price (SAR)'  size="medium"
                  value={value?.price_variation[index]?.day_shift_price} onChange={(e) => {setValue(`price_variation[${index}].day_shift_price`, formatPrice(e?.target?.value))}} />
                {touched?.price_variation && errors?.price_variation &&
                touched?.price_variation[index]?.day_shift_price && 
                errors?.price_variation[index]?.day_shift_price && 
                <Error error={errors?.price_variation[index]?.day_shift_price} />}
              </FormControl>
              </StyledTableCell>
              <StyledTableCell align="center">
              <FormControl fullWidth>
                <TextField disabled={disabled} name={`price_variation[${index}].night_shift_price`} placeholder='Enter Price (SAR)' size="medium" 
                  value={value?.price_variation[index]?.night_shift_price} onChange={(e) => {setValue(`price_variation[${index}].night_shift_price`, formatPrice(e?.target?.value))}} />
                {touched?.price_variation && errors?.price_variation && 
                touched?.price_variation[index]?.night_shift_price && 
                errors?.price_variation[index]?.night_shift_price && 
                <Error error={errors?.price_variation[index]?.night_shift_price} />}
              </FormControl>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PriceVariation