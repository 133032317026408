import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography, InputLabel } from '@mui/material';
import { EditTwoTone } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import Loader from 'app/components/Loader/Loader';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import UserServices from 'app/apis/User/UserServices';
import MaterialsEquipmentsServices from 'app/apis/MaterialsEquipments/MaterialsEquipmentsServices';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

function CreateUser() {
  const {id,name = ''} = useParams();
  const navigate = useNavigate();
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [station, setStation] = useState([]);

  // User Validation Schema
  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Name field is Required'),
    email: Yup.string().required('Email field is Required'),
    employee_type_id: Yup.number().required('Type field is Required'),
    is_station_required:Yup.boolean(),
    station_id: Yup.number().when(['is_station_required'],(e) => {
      if(e[0] === true){
        return Yup.number().required('Station field is Required')
      }
      return Yup.number().notRequired()
    }),
  });

  // handle station validation
  const handleStationRequired = (id,setFieldValue) => {
    const check = userTypes?.findIndex((e) => e?.id === id)
    if(userTypes[check]?.is_station_required){
      setFieldValue('is_station_required',true);
    } else {
      setFieldValue('is_station_required',false);
    }
  }
  // *For Getting User Types
  const getUserTypes = async () => {
    try {
      const { status, responseCode, data } = await UserServices.getUserTypes()
      if (status === true && responseCode === 200) {
        setUserTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Stations
  const getStations = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getStations()
      if (status === true && responseCode === 200) {
        setStation(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting User By Id
  const getUserById = async (id) => {
    try {
      setIsLoading(true)
      const { status, responseCode, data } = await UserServices.getUserById(id)
      if (status === true && responseCode === 200) {
        setUserData(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }
  const handleSubmitUser = async (values) => {
    try {
      setLoading(true)
      delete values?.is_station_required
      if(id){
        const { status, responseCode, message } = await UserServices.patchUserById({id:id,data:values})
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await UserServices.postUser(values)
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){getUserById(id)}
    getUserTypes();
    getStations();
  }, [id])


  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          name: id ? userData?.name : '',
          email: id ? userData?.email :'',
          employee_type_id: id ? userData?.type : '',
          station_id: id ? userData?.station_id : '',
          is_station_required:false,
        }}
        validationSchema={UserSchema}
        onSubmit={values => {handleSubmitUser(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>
              {!id ? 'Create New User' : `${allowEdit ? 'Edit' : 'User'} ${name}`}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} startIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Users',id ? `${allowEdit ? 'Edit' : ''} User` : 'Create New User']} />
          </Box>
          {isLoading ? <Loader /> :
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>User Name</FormLabel>
                <TextField name="name" placeholder='User Name' size="medium" value={values?.name}
                onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} 
                  disabled={!allowEdit}
                />
                {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Email</FormLabel>
                <TextField name="email" placeholder='Email' type='email' size="medium" value={values?.email} 
                onChange={(e) => {setFieldValue(`email`, e?.target?.value)}} disabled={!allowEdit}/>
                {touched.email && errors.email && <CustomErrorBox>{errors.email}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Type</FormLabel>
                <Select name="employee_type_id"  size="medium"  
                  disabled={!allowEdit}
                  value={values?.employee_type_id ? values?.employee_type_id : 0}
                  onChange={(e) => {
                    setFieldValue(`employee_type_id`, e?.target?.value);
                    handleStationRequired(e?.target?.value,setFieldValue)}}
                  sx={{color:values?.employee_type_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Type
                  </MenuItem>
                  {userTypes?.length > 0 ? userTypes?.map((userTypeItem,index) => (
                  <MenuItem key={index} value={userTypeItem?.id}>{userTypeItem?.type}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.employee_type_id && errors.employee_type_id && <CustomErrorBox>{errors.employee_type_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
                <Select name="station_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.station_id ? values?.station_id : 0}
                  onChange={(e) => {setFieldValue(`station_id`, e?.target?.value)}}
                  sx={{color:values?.station_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Station
                  </MenuItem>
                  {station?.length > 0 ? station?.map((stationItem,index) => (
                  <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {errors.station_id && <CustomErrorBox>{errors.station_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
            </Grid>
            {allowEdit && <Box textAlign={'right'} my={2}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Box>}
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default CreateUser