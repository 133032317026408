import { FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  TextField,  Typography,  styled, tableCellClasses } from '@mui/material'
import { Error } from 'app/components/UI/Error';
import colors from 'app/styles/colors';
import React from 'react'

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'white',
      color: colors.secondaryDark,
      fontSize:18, fontWeight:600
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  

function Duration({data,value,setValue,touched,errors,disabled}) {
  return (
    <TableContainer component={Paper} sx={{my:2}}>
      <Table sx={{ minWidth: 700,"& .MuiTableCell-root": {
      border: '1px solid #ddd',
    } }} >
        <TableHead>
          <StyledTableCell align="left" colSpan={2} style={{backgroundColor:'#eee'}}>Service Duration</StyledTableCell>
          <TableRow>
            <StyledTableCell align="center" width={'50%'}>Aircraft</StyledTableCell>
            <StyledTableCell align="center" width={'50%'}>Duration</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row,index) => (
            <TableRow key={index}>
              <StyledTableCell align="center" >{row.aircraft_served_name}</StyledTableCell>
              <StyledTableCell align="center">
              <FormControl fullWidth>
              <TextField disabled={disabled} name={`duration[${index}].duration`} placeholder='Enter Duration in Hours' type='number' size="medium" 
                value={value?.duration[index]?.duration} onChange={(e) => {setValue(`duration[${index}].duration`, e?.target?.value)}} />
                {touched?.duration && errors?.duration &&
                touched?.duration[index]?.duration && 
                errors?.duration[index]?.duration && 
                <Error error={errors?.duration[index]?.duration} />}
              </FormControl>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Duration