import { getCookie } from 'app/hooks/useCookie';
import axios from 'axios';

const server = 'https://api-joborder.jetglow.tech/api';

const instanceTemp = axios.create({
  baseURL: server,
});

instanceTemp.interceptors.request.use((request) => {

  const token = getCookie('userinfo')?.token

  request.headers = {
    'Accept': "application/json, text/plain, */*",
    'Authorization': `Bearer ${token}`,
  }
  return request
});

instanceTemp.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      error
    )
)

export default instanceTemp;