import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hamburger: {xs:false, md:true},
}

export const slicer = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setHamburger: (state, action) => {
      state.hamburger = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setHamburger } = slicer.actions

export default slicer.reducer