const JobOrderRoutes = {
    getJobOrderDetails: "/admin/jobOrderById/",
    getAllJobOrderDetails: "/admin/jobOrderList",
    getCheckListDetails: "/admin/getJobOrderCheckList/", // 2 for close and 1 for pre / order id
    updateJobOrderStatus:"/admin/updateJobOrderStatus/",
    postJobOrderDetails: "/admin/createJobOrder",
    deleteJobOrder:"admin/jobOrder/",
    getJobOrderForEditById:"/admin/jobOrderForEditById/",
    updateJobOrder:"/admin/updateJobOrder/",
    getVAT:"/admin/getVat",
    getTimeSlots:"/admin/jobOrderSlots",
  };
  
  export default JobOrderRoutes