import { ArrowBackRounded } from '@mui/icons-material'
import colors from 'app/styles/colors';
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ArrowBackButton() {
  const navigate = useNavigate();
  return (
    <ArrowBackRounded
      onClick={() => navigate(-1)}
        sx={{
          backgroundColor: colors.white,color:'#919191',boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;',
          borderRadius: "6px",cursor:'pointer',mr:2,fontSize:'30px',fontWeight:'bold',p: 0.75,
        }}
      />
  )
}

export default ArrowBackButton