import React, { Fragment, useEffect, useState } from 'react'
import { Box, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography, InputLabel } from '@mui/material';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { Form, Formik } from 'formik';
import { EditTwoTone } from '@mui/icons-material';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import EmployeeServices from 'app/apis/Employees/EmployeesService';
import MaterialsEquipmentsServices from 'app/apis/MaterialsEquipments/MaterialsEquipmentsServices';
import TeamServices from 'app/apis/Teams/TeamsServices';
import { AutocompleteInput } from 'app/components/Input/Autocomplete';
import * as Yup from 'yup';
import Loader from 'app/components/Loader/Loader';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const EmployeeSchema = Yup.object().shape({
  name: Yup.string().required('Name field is Required'),
  employee_type_id: Yup.number().required('Type field is Required'),
  email: Yup.string().when(['employee_type_id'],(e) => {
    if(e[0] !== 2){
      return Yup.string().required('Email field is Required')
    }
    return Yup.string().notRequired()
  }),
  station_id: Yup.number().required('Station field is Required'),
});

function CreateEmployee() {
  const {id,name = ''} = useParams();
  const navigate = useNavigate();
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [teams, setTeams] = useState([]);
  const [station, setStation] = useState([]);
  const [employeeData, setEmployeeData] = useState('');

  // *For Getting Employee Types
  const getEmployeeTypes = async () => {
    try {
      const { status, responseCode, data } = await EmployeeServices.getEmployeeTypes()
      if (status === true && responseCode === 200) {
        setEmployeeTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Teams
  const getTeams = async (obj) => {
    try {
      const { status, responseCode, data } = await TeamServices.getTeams(obj)
      if (status === true && responseCode === 200) {
        setTeams(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Stations
  const getStations = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getStations()
      if (status === true && responseCode === 200) {
        setStation(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Employee By Id
  const getEmployeeById = async (id) => {
    try {
      setIsLoading(true)
      const { status, responseCode, data } = await EmployeeServices.getEmployeeById(id)
      if (status === true && responseCode === 200) {
        setEmployeeData(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }
  const handleSubmitEmployee = async (values) => {
    try {
      let newValues = {...values}
      if(newValues?.teams?.length > 0){
        const teams = []
        for (let teamsArray = 0; teamsArray < newValues?.teams?.length; teamsArray++) {
          teams.push(newValues?.teams[teamsArray]?.id)
        }
        newValues = {...newValues, teams:teams}
      } else {
        delete newValues?.teams
      }
      setLoading(true)
      if(id){
        const { status, responseCode, message } = await EmployeeServices.patchEmployeeById({id:id,data:newValues})
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await EmployeeServices.postEmployee(newValues)
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){
      getEmployeeById(id)
    }
    getEmployeeTypes();
    getTeams({limit:'1000',page:1});
    getStations();
  }, [id])


  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          name: id ? employeeData?.name : '',
          email: id ? employeeData?.email :'',
          employee_type_id: id ? employeeData?.employee_id : '',
          station_id: id ? employeeData?.station_id : '',
          teams: id ? employeeData?.teams : [],
        }}
        validationSchema={EmployeeSchema}
        onSubmit={values => {handleSubmitEmployee(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>
              {!id ? 'Create New Employee' : `${allowEdit ? 'Edit' : ''} Employee ${name}`}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} startIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Employees',id ? `${allowEdit ? 'Edit' : ''} Employee` : 'Create New Employee']} />
          </Box>
          {isLoading ? <Loader /> :
          <Fragment>
            <Grid container spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Employee Name</FormLabel>
                <TextField name="name" placeholder='Employee Name' size="medium" value={values?.name}
                onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} 
                  disabled={!allowEdit}
                />
                {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
              {values?.employee_type_id !== 2 && 
                <FormControl fullWidth>
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Email</FormLabel>
                  <TextField name="email" placeholder='Email' type='email' size="medium" value={values?.email} 
                  onChange={(e) => {setFieldValue(`email`, e?.target?.value)}} disabled={!allowEdit}/>
                  {touched.email && errors.email && <CustomErrorBox>{errors.email}</CustomErrorBox>}
                </FormControl>}
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Type</FormLabel>
                <Select name="employee_type_id"  size="medium"  
                  disabled={!allowEdit}
                  value={values?.employee_type_id ? values?.employee_type_id : 0}
                  onChange={(e) => {setFieldValue(`employee_type_id`, e?.target?.value)}}
                  sx={{color:values?.employee_type_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Type
                  </MenuItem>
                  {employeeTypes?.length > 0 ? employeeTypes?.map((employeeTypeItem,index) => (
                  <MenuItem key={index} value={employeeTypeItem?.id}>{employeeTypeItem?.type}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.employee_type_id && errors.employee_type_id && <CustomErrorBox>{errors.employee_type_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Teams</FormLabel>
                <AutocompleteInput disabled={!allowEdit} name="teams" data={teams} label={"Teams"} 
                value={values?.teams}
                limitTag={1}
                onChange={(e,value) => {setFieldValue('teams',value)}} />
                {touched.teams && errors.teams && <CustomErrorBox>{errors.teams}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Station</FormLabel>
                <Select name="station_id"  size="medium"
                  disabled={!allowEdit}  
                  value={values?.station_id ? values?.station_id : 0}
                  onChange={(e) => {setFieldValue(`station_id`, e?.target?.value)}}
                  sx={{color:values?.station_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Station
                  </MenuItem>
                  {station?.length > 0 ? station?.map((stationItem,index) => (
                  <MenuItem key={index} value={stationItem?.id}>{stationItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.station_id && errors.station_id && <CustomErrorBox>{errors.station_id}</CustomErrorBox>}
                </FormControl>
              </Grid>
            </Grid>
            {allowEdit && <Box textAlign={'right'} my={2}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Box>}
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default CreateEmployee