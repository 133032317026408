import { Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, Typography } from '@mui/material';
import { Add, DeleteOutline } from '@mui/icons-material';
// Formik Import
import { ErrorMessage, FieldArray } from 'formik';
// Component Import
import { AutocompleteInput } from 'app/components/Input/Autocomplete';
import { CustomErrorBox } from './CreateJobOrder';
import colors from 'app/styles/colors';

function SelectService({allowEdit,values,isClicked,setFieldValue,serviceCategories,serviceDataOptions,getServiceByCategoryAndAircraftId}) {
  return (
    <FieldArray
      name="services"
      render={arrayHelpers => (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              {values?.services?.length < serviceCategories?.length && 
              <Button
                type="button" variant='outlined' disabled={!allowEdit}
                onClick={() => arrayHelpers.push({service_category_id: '',service_category_name:'',services:[]})}
                startIcon={<Add />} sx={{textTransform:'capitalize'}}>Add</Button>}
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            {values?.services?.map((_, index) => (
              <Grid item container spacing={2}  key={index} alignItems={'flex-start'}>
                <Grid item xs={12} md={values?.services?.length === 1 ? 6 : 5.70}>
                  <FormControl fullWidth size="small">
                    <FormLabel sx={{my:1,  color:colors.formLabel, fontWeight:'bold'}}>Service Category</FormLabel>
                    <Select
                      size='medium' disabled={!allowEdit || (!values?.aircraft_id )}
                      name={`services[${index}].service_category_id`}
                      value={values.services[index]?.service_category_id ? values.services[index]?.service_category_id : 0}
                      onChange={(e) => {setFieldValue(`services[${index}].service_category_id`, e?.target?.value);
                      setFieldValue(`services[${index}].service_category_name`,serviceCategories?.find((v) => v?.id === e?.target?.value)?.name);
                      setFieldValue(`services[${index}].services`, []);
                      getServiceByCategoryAndAircraftId({categoryId:e?.target?.value,aircraftId:values?.aircraft_id},index)}}
                      sx={{color:values.services[index]?.service_category_id === '' ? colors.labelColor : ''}}
                    >
                    <MenuItem value={0} disabled sx={{display:'none'}}>
                      Select Service Category
                    </MenuItem>
                    {serviceCategories?.map((item,index) => (
                      <MenuItem key={index} value={item?.id} 
                      sx={{display: values?.services?.some((service) => service?.service_category_id === item?.id) ? 'none' : 'block'}}>{item?.name}</MenuItem>
                      ))}
                    </Select>
                    {isClicked && <ErrorMessage
                      name={`services[${index}].service_category_id`}
                      render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                    />}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={values?.services?.length === 1 ? 6 : 5.70}>
                <FormControl fullWidth size="small">
                  <FormLabel sx={{ my:1, color:colors.formLabel, fontWeight:'bold'}}>Add Service</FormLabel>
                  <AutocompleteInput name={`services[${index}].services`} limitTag={1} identifier='service_name'
                    disabled={!allowEdit ? !allowEdit : values?.services[index]?.service_category_id === ""} 
                    data={serviceDataOptions[index]?.length > 0 ? serviceDataOptions[index] : []}  
                    value={values?.services[index]?.services} placeholder={'Select Service'}
                    onChange={(e, value) => {setFieldValue(`services[${index}].services`,value)}} />
                 {isClicked && <ErrorMessage
                    name={`services[${index}].services`}
                    render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                  />}
                </FormControl>
                </Grid>
                {index !== 0 && 
                <Grid item xs={12} md={0.5} >
                  <Box disabled={!allowEdit} type="button" onClick={() => {arrayHelpers.remove(index);
                  // setServiceCategory((prev) =>{
                  //   const value = [...prev]
                  //   if( index <= value?.length - 1){
                  //     value?.splice(index,1)
                  //     return value
                  //   }
                  //   else{
                  //     return value
                  //   }
                  // })
                  }} sx={{backgroundColor: colors.dawnPink, width:'55px', height:'55px', borderRadius: '5px', display:'flex', alignItems:"center", justifyContent:'center', mt:5, cursor:'pointer'}}>
                    <DeleteOutline sx={{color:colors.danger}} />
                  </Box>
                </Grid>}
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    />
  )
}

export default SelectService