import { Box,Typography } from "@mui/material";
import { ErrorToaster } from "../Toaster/Toaster";
import { DeleteTwoTone, EditTwoTone, FileUploadOutlined } from "@mui/icons-material";
import colors from "app/styles/colors";

const { useCallback, Fragment } = require("react");
const { useDropzone } = require("react-dropzone");

export const DropZone = ({isDisable=false,uploadFile, setUploadFile }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    onDrop: useCallback(
      (acceptedFiles) => {
        const MAX_FILE_SIZE = 2048; // 2MB
        const fileSizeKiloBytes = acceptedFiles[0]?.size / 1024;
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
          ErrorToaster('File size is greater than 2 MB')
        } else {
          setUploadFile(
            'logo',
            acceptedFiles?.map((file) =>
              Object?.assign(file, {
                preview: URL?.createObjectURL(file)
              })
            )
          );
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setUploadFile]
    )
  });

  const thumbs = uploadFile?.map((file) => (
    <Box
      key={file?.path}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
    {}
      <img
        src={file?.preview ? file?.preview : file ? file  : ''}
        style={{ width: '200px', height: '200px', objectFit: 'cover ',borderRadius:'5px' }}
        alt="Upload"
        key={file.path}
        onLoad={() => {
          URL.revokeObjectURL(file.preview);
        }}
      />

      <Typography sx={{ margin: '0px 20px 0px 20px' }}>{file?.path}</Typography>
      {isDisable && 
      <Box sx={{ml:2, display:'flex',alignItems:'center',gap:2}}>
        <Box {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <EditTwoTone sx={{cursor:'pointer', color:colors.primary ,p:0.5,backgroundColor:colors.primaryLight,borderRadius:2, fontSize:20}} 
          />
        </Box>
        <DeleteTwoTone sx={{cursor:'pointer', color: colors.danger,p:0.6,backgroundColor:colors.dawnPink,borderRadius:2, fontSize:20}} 
            onClick={() => setUploadFile('logo', [])}
          />
      </Box>}
    </Box>
  ));

  return (
    <Box sx={{display:'flex', alignItems:'flex-start',gap:2}}>
      {isDisable && 
      <Box
        sx={{height: '200px',border: `2px dashed ${colors.formLabel}`,width:'40%',
          borderRadius: '5px',textAlign:'center',cursor: 'pointer'}}>
        <Box
          style={{
            height: '100%',width: '100%',
            display: 'flex',justifyContent: 'center',
            alignItems: 'center',flexDirection: 'column'}}  
          {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <FileUploadOutlined sx={{color:colors.springGreen, fontSize:44}} />
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: colors.formLabel,px:2 }}
            >
              Drag and drop your image here, or <u style={{ color: '#F37f43' }}>Click to browse</u>
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 500, color: colors.formLabel }}
            >
              <i className="fa-solid fa-circle fa-2xs" /> JPG,PNG
            </Typography>
          </Box>
        </Box>
      </Box>}
      <Box >{thumbs}</Box>
    </Box>
  );
};