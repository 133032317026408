import { Close } from '@mui/icons-material'
import { Box, FormControl, FormLabel, MenuItem, Modal, Select, TextField, Typography, styled } from '@mui/material'
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import { PrimaryButton, SecondaryButton } from 'app/components/Buttons/Button';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import colors from 'app/styles/colors';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 555,
  backgroundColor: 'background.paper',
  boxShadow: 24,borderRadius:2,
  p: 2,
};

const subServiceSchema = Yup.object().shape({
  head_id:Yup.number().required('Service Category is required'),
  name: Yup.string().required("Sub Category Name is required"),

});

function AddSubCategory({category,categoryId}) {
  const {id} = useParams();
  const navigate = useNavigate();
  const {pathname} = useLocation();
	const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);


  // *For Getting Service Categories
  const getAllServiceCategories = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:10000,page:1,status:1})
      if (status === true && responseCode === 200) {
        setCategoryData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // Getting SubService Data for Update
  const getSubServiceCategoryById = async (id) => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceSubCategoryByID(id)
      if (status === true && responseCode === 200) {
        setSubCategoryData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  const handleSubmitSubServiceCategory = async (values) => {
    try {
      setLoading(true)
      if(id){
        const { status, responseCode, message } = await ServiceCategoryServices.patchServiceCategoryAndSubCategoryById({id:id,data:{name:values?.name,status:subCategoryData?.status}})
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await ServiceCategoryServices.postSubServiceCategory(values)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getAllServiceCategories()
    if(id){
      getSubServiceCategoryById(id)
    }
  }, []);

  
  return (
    <Modal
    open={true}
    // onClose={handleClose}
    >
    <Box sx={style}>
		<Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px'}}>
      {id ? `Edit Sub Category ` : 'Add New Sub Category'} 
			</Typography>
			<Close sx={{cursor:'pointer'}} onClick={() => navigate(-1)}/>
			</Box>
			<Formik
        enableReinitialize
        initialValues={{head_id:categoryId ? categoryId : id ? subCategoryData?.category_id : '',name:id ? subCategoryData?.sub_category_name : ''}}
        validationSchema={subServiceSchema}
      	onSubmit={values => {handleSubmitSubServiceCategory(values);}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
        {pathname?.split('/')?.includes('view-sub-category') && 
          <FormControl fullWidth>
						<FormLabel sx={{my:2, color:colors.formLabel, fontWeight:'bold'}}>Category Name</FormLabel>
						<Select name="head_id"  size="medium"
              value={values?.head_id ? values?.head_id : 0}
              disabled={id ? true : false}
              onChange={(e) => {setFieldValue(`head_id`, e?.target?.value)}}
              sx={{color:values?.head_id === '' ? colors.labelColor : ''}} >
              <MenuItem value={0} disabled sx={{display:'none'}}>
                Select Category
              </MenuItem>
              {categoryData?.map((categoryItem,index) => (
                <MenuItem key={index} value={categoryItem?.id}>{categoryItem?.name}</MenuItem>
              ))}
            </Select>
            {touched.head_id && errors.head_id && <CustomErrorBox>{errors.head_id}</CustomErrorBox>}
					</FormControl>}
          <FormControl fullWidth>
						<FormLabel sx={{my:2, color:colors.formLabel, fontWeight:'bold'}}>Sub Category Name</FormLabel>
						<TextField name="name" placeholder='Sub Category Name' size="medium" value={values?.name} onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
						{touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
					</FormControl>
					<Box sx={{textAlign:'right',my:2}}>
            <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
            <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
					</Box>
				</Form>
				)}
				</Formik>
    </Box>
    </Modal>
  )
}

export default AddSubCategory