import { get, post, patch, deleted } from 'app/apis';
import MaterialsEquipmentsRoutes from './MaterialsEquipments.Routes';

const MaterialsEquipmentsServices = {
  getMaterialsEquipmentsTypes: async () => {
    const data = await get(MaterialsEquipmentsRoutes.getMaterialsEquipmentsTypes);
    return data;
  },
  getMaterialsEquipmentTypesById: async (id) => {
    const data = await get(MaterialsEquipmentsRoutes.getMaterialsEquipmentTypesById+`${id}?limit=1000&page=1`);
    return data;
  },
  // Service Equipment
  getServiceEquipments: async (obj) => {
    const data = await get(MaterialsEquipmentsRoutes.getServiceEquipments+`?limit=${obj?.limit}&page=${obj?.page}`);
    return data;
  },
  deleteMaterialsEquipmentsById: async (id) => {
    const data = await deleted(MaterialsEquipmentsRoutes.deleteMaterialsEquipmentsById+`${id}`);
    return data;
  },
  getRampStatuses: async () => {
    const data = await get(MaterialsEquipmentsRoutes.getRampStatuses);
    return data;
  },
  postMaterialsEquipments: async (obj) => {
    const data = await post(MaterialsEquipmentsRoutes.postMaterialsEquipments,obj);
    return data;
  },
  patchMaterialsEquipmentsById: async (obj) => {
    const data = await patch(MaterialsEquipmentsRoutes.patchMaterialsEquipmentsById+`${obj.id}`,obj.data);
    return data;
  },
  getStations: async () => {
    const data = await get(MaterialsEquipmentsRoutes.getStations);
    return data;
  },
  getServiceEquipmentById: async (id) => {
    const data = await get(MaterialsEquipmentsRoutes.getServiceEquipmentById+`${id}`);
    return data;
  },
  patchServiceEquipmentStatus: async (obj) => {
    const data = await patch(MaterialsEquipmentsRoutes.patchServiceEquipmentStatus+`${obj.id}`,obj.data);
    return data;
  },
}

export default MaterialsEquipmentsServices