import React, { useState } from "react";
import {Box,Stack,TextField, Typography,} from "@mui/material";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import UploadImage from "./UploadImage";
import SubTaskList from "./SubTaskList";
import colors from "app/styles/colors";


function PreOrderWorkFlow({checkList}) {
  const [file, setFile] = useState([]);

  return (
    <Stack>
      <Box my={2}>
        {/* Sub Tab List */}
        {checkList?.checkListType?.length > 0 ? 
        <SubTaskList data={checkList?.checkListType} /> : 
        <Typography color={colors.secondaryDark} textAlign={'center'} fontWeight={600}>No Check Points</Typography>}
        {/* End */}
        {/* <TextField
          fullWidth
          multiline
          minRows={3}
          name="pre_job_comments"
          placeholder="Pre Job Comments"
          defaultValue={checkList?.comments}
          disabled
          size="small"
          sx={{ mt: 2 }}
        /> */}
        <UploadImage uploadFile={file} setUploadFile={setFile} data={checkList && checkList?.images?.length > 0 ? checkList?.images : []}
          showUploadImage={checkList?.is_before_checklist_done === 1 ? false : true} />
      </Box>
    </Stack>
  );
}

export default PreOrderWorkFlow;
