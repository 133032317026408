import { ArrowBackTwoTone, DoneTwoTone, EditTwoTone } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography, styled } from '@mui/material';
import { PrimaryButton, SecondaryButton } from 'app/components/Buttons/Button';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

function RequestMaintenance() {
    const navigate = useNavigate();
    const {id,status=''} = useParams();
    const [allowEdit, setAllowEdit] = useState(status?.toLowerCase() === 'pending' || status?.toLowerCase() === '' ? false : true);
    const [loading, setLoading] = useState(false);
  return (
    <Layout>
      <Formik
        initialValues={{
          equipment:status?.toLowerCase() === 'closed' ? 1 : '',
          maintenance_type:status?.toLowerCase() === 'closed' ? 1 : '',
          station: status?.toLowerCase() === 'closed' ? 2 : '',
          request_date: status?.toLowerCase() === 'closed' ? '2023-08-15' : '',
          notes:status?.toLowerCase() === 'closed' ? 'This is only view mode' : ''
        }}
    //   validationSchema={RequestMaintenanceSchema}
      onSubmit={values => {
        //  console.log(values);
        // handleSubmitRequestMaintenance(values);
        }}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between', my:2}}>
            <Box sx={{display:'flex', alignItems:'center', my:2}}>
              <ArrowBackTwoTone
                onClick={() => navigate(-1)}
                  sx={{
                    backgroundColor: colors.primaryLight,color:colors.primary,
                    borderRadius: "6px",cursor:'pointer',mr:2,
                    p: 0.75,
                  }}
                />
              <Typography variant='h4' color={colors.primary} my={1.5}>{(status?.toLowerCase() === '' || status?.toLowerCase() === 'pending' ) ? `Edit Maintenance Job Order # ${id}` : `Maintenance Request Job Order # ${id}`}</Typography>
            </Box>
            {status?.toLowerCase() !== 'closed' && <Box sx={{display:'flex', alignItems:'center', columnGap:2}}>
              <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />
             {status?.toLowerCase() === 'pending' && <PrimaryButton title={"Closed"}  onClick={() => navigate(-1)} endIcon={<DoneTwoTone />} />}
            </Box>}
          </Box>
          <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
            boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Equipment</FormLabel>
              <Select name="equipment"  size="medium" 
                disabled={status?.toLowerCase() === 'closed' ? true : !allowEdit} 
                value={values?.equipment ? values?.equipment : 0}
                onChange={(e) => {setFieldValue(`equipment`, e?.target?.value)}}
                sx={{color:values?.equipment === '' ? colors.labelColor : ''}} >
                <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Equipment
                </MenuItem>
                <MenuItem value={1}>Equipment 1</MenuItem>
                <MenuItem value={2}>Equipment 2</MenuItem>
                <MenuItem value={3}>Equipment 3</MenuItem>
              </Select>
              {touched.equipment && errors.equipment && <CustomErrorBox>{errors.equipment}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Maintenance Type</FormLabel>
              <Select name="maintenance_type"  size="medium" 
                disabled={status?.toLowerCase() === 'closed' ? true : !allowEdit} 
                value={values?.maintenance_type ? values?.maintenance_type : 0}
                onChange={(e) => {setFieldValue(`maintenance_type`, e?.target?.value)}}
                sx={{color:values?.maintenance_type === '' ? colors.labelColor : ''}} >
                <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Maintenance Type
                </MenuItem>
                <MenuItem value={1}>Maintenance Type 1</MenuItem>
                <MenuItem value={2}>Maintenance Type 2</MenuItem>
                <MenuItem value={3}>Maintenance Type 3</MenuItem>
              </Select>
              {touched.maintenance_type && errors.maintenance_type && <CustomErrorBox>{errors.maintenance_type}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Station</FormLabel>
              <Select name="station"  size="medium"
                disabled={status?.toLowerCase() === 'closed' ? true : !allowEdit}   
                value={values?.station ? values?.station : 0}
                onChange={(e) => {setFieldValue(`station`, e?.target?.value)}}
                sx={{color:values?.station === '' ? colors.labelColor : ''}} >
                <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Station
                </MenuItem>
                <MenuItem value={1}>Station 1</MenuItem>
                <MenuItem value={2}>Station 2</MenuItem>
                <MenuItem value={3}>Station 3</MenuItem>
              </Select>
              {touched.station && errors.station && <CustomErrorBox>{errors.station}</CustomErrorBox>}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Request Date</FormLabel>
              <TextField disabled={status?.toLowerCase() === 'closed' ? true : !allowEdit}  name="request_date" type='date' placeholder="Request Date" size="medium"
              value={values?.request_date}
              onChange={(e) => {setFieldValue(`request_date`, e?.target?.value)}} />
              {touched.request_date && errors.request_date && <CustomErrorBox>{errors.request_date}</CustomErrorBox>}
              </FormControl>
            </Grid>
            {/* Notes */}
            <Grid item xs={12} md={12}>
              <FormControl fullWidth>
              <FormLabel sx={{mb:1.5, color:colors.primary}}>Notes</FormLabel>
              <TextField disabled={status?.toLowerCase() === 'closed' ? true : !allowEdit} name="notes" value={values?.notes} placeholder='Enter Instructions here' size="medium" multiline minRows={3}
               onChange={(e) => {setFieldValue(`notes`, e?.target?.value)}} />
              {touched.notes && errors.notes && <CustomErrorBox>{errors.notes}</CustomErrorBox>}
              </FormControl>
            </Grid>
            {status?.toLowerCase() !== 'closed' && 
            <Grid item xs={12} md={12} textAlign={'right'}>
              <SecondaryButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <PrimaryButton title={id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Grid>}
          </Grid>
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default RequestMaintenance