import { Add, Block, CheckCircleOutlineOutlined, Delete, MoreVert } from '@mui/icons-material';
import { Box, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmployeeServices from 'app/apis/Employees/EmployeesService';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { SecondaryButton } from 'app/components/Buttons/Button';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// Library Import
import Swal from 'sweetalert2';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'name',label: 'Employee Name',clickAble: true},
  {id: 'employee_type',label: 'Type',},
  // {id: 'email',label: 'Email',},
  {id: 'teams',label: 'Team(s)',},
  {id: 'station',label: 'Station',},
  {id:'status', label:'Status', }
];

function ManageEmployee() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoading, setIsLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [rowDetails, setRowDetails] = useState();
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // For Deleting Employee
  const deleteEmployeeById = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this record!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText:'No',
      customClass: {
        container: 'overlay', // Apply the overlay style
        popup: 'swal-modal'   // Apply the modal style
    },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
        const { status, responseCode, message } = await EmployeeServices.deleteEmployeeById(id)
        if (status === true && responseCode === 200) {
          Swal.fire({
            title:'Success!',
            text:message,
            icon:'success',
            customClass: {
              container: 'overlay', // Apply the overlay style
              popup: 'swal-modal'   // Apply the modal style
            }}).then(async() =>
              await getEmployees(rowsPerPage,page)
            )} 
        } catch (error) {
          ErrorToaster(error)
        }
      }
    }) 
  }

  // For Suspending Employee
  const patchEmployeeStatus = async (obj) => {
    try {
      const value = {id:obj?.id,data:{status:+obj?.status === 1 ? 0 : 1}}
      const { status, responseCode, message } = await EmployeeServices.patchEmployeeStatus(value)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        getEmployees({limit:rowsPerPage,page:page})
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Employees
  const getEmployees = async (rowsPerPage,page) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,count } = await EmployeeServices.getEmployees({limit:rowsPerPage,page:page})
      if (status === true && responseCode === 200) {
        setEmployeeData(data);
        setCount(count);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getEmployees(rowsPerPage,page)
  }, [page, rowsPerPage]);

  return (
    <Layout>
    <Box>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Employee Management</Typography>
        <SecondaryButton title={"Create New"}  onClick={() => navigate('add')} startIcon={<Add />} />
      </Box>
      <BreadCrumbs currentPage={['Manage Employee']} />
      {isLoading ? <Loader /> : 
      <Fragment>
      {employeeData?.length > 0 ? 
      <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
      <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
        <Table className={classes.table} stickyHeader aria-label="sticky table" >
            <TableHead >
              <TableRow >
                {columns.map((column,index) => (
                  <StyledTableCell
                    key={`Table-${column.id}-${index}`}
                    style={{ fontWeight:600 }}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
                <StyledTableCell align={'center'} style={{ fontWeight:600 }}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employeeData?.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`Table-row-${row.code}-${index}`}>
                    {columns.map((column,innerIndex) => {
                      const value = row[column.id];
                      return (
                        <Fragment key={`${index}-${innerIndex}`}>
                        {column.id === 'status' ? (
                        <StyledTableCell key={column.id} >
                        <StatusChip value={+value === 1 ? 'Active' : 'Suspended'} />
                        </StyledTableCell>
                        ) : column.id === 'teams' ? (
                        <StyledTableCell key={column.id} >
                        {row?.teams?.length > 0 ? (
                          <Box>
                            {row?.teams?.map((team,teamIndex) => (
                              <Typography key={`Team-${teamIndex}`}>{team}</Typography>
                            ))}
                          </Box>
                        ) : (
                          <Typography>--</Typography>
                        )}
                        </StyledTableCell>
                        ) : (
                        <StyledTableCell key={column.id} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                        onClick={() =>  {column.clickAble && navigate(`edit/${row?.name}/${row.id}`)}}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </StyledTableCell>)}
                        </Fragment>
                      );
                    })}
                    <StyledTableCell align='center'>
                      <MoreVert sx={{color:colors.primary, cursor:'pointer'}} onClick={(event) => {setAnchorEl(event.currentTarget);setRowDetails(row)}} />
                    </StyledTableCell>
                  </TableRow>
                );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50,100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page-1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> : 
      <Box display={'flex'}
        p={6.25}
        alignItems={'center'}
        justifyContent={'center'}>
        <Typography variant="h5" fontWeight={400} >
          No Data
        </Typography>
      </Box>}
      </Fragment>}
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{style: {width: '180px',borderRadius:'5px',}}}
      >
        <MenuItem sx={{'&:hover':{backgroundColor:'#008035',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}} 
         onClick={() => {setAnchorEl(null);patchEmployeeStatus(rowDetails)}}>
          <Typography>{+rowDetails?.status === 1 ? 'Suspend' : 'Enable'}</Typography>
          {+rowDetails?.status === 1 ?  <Block fontWeight={'bold'} /> : <CheckCircleOutlineOutlined fontWeight={'bold'} />}
         </MenuItem>
        <MenuItem sx={{'&:hover':{backgroundColor:'#DC4949',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}}
        onClick={() => {setAnchorEl(null);deleteEmployeeById(rowDetails?.id)}}>
          <Typography>Delete</Typography>
          <Delete fontWeight={'bold'} />
        </MenuItem>
      </Menu>
    </Box>
    </Layout>
  )
}

export default ManageEmployee