import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import {  Form, Formik } from 'formik';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import PriceVariation from './TableComponents/PriceVariation';
import Requirement from './TableComponents/Requirement';
import Duration from './TableComponents/Duration';
import * as Yup from 'yup';
import { Add, EditTwoTone } from '@mui/icons-material';
import AddCategory from './Modal/AddCategory';
import { AutocompleteInput } from 'app/components/Input/Autocomplete';
import AddSubCategory from './Modal/AddSubCategory';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import AircraftServices from 'app/apis/Aircraft/AircraftServices';
import MaterialsEquipmentsServices from 'app/apis/MaterialsEquipments/MaterialsEquipmentsServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import Loader from 'app/components/Loader/Loader';


const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const ServiceSchema = Yup.object().shape({
  service_category: Yup.number().required('Service Category field is Required'),
  // sub_category: Yup.number().required('Service Sub Category field is Required'),
  service_name: Yup.string().required('Service Name field is Required'),
  description: Yup.string().required('Description field is Required'),
  aircraft_served: Yup.array().min(1,"At least select one Aircraft"),
  price_variation: Yup.array().of(
    Yup.object().shape({
      day_shift_price: Yup.string().required('Day Shift Price is Required'),
      night_shift_price: Yup.string().required('Night Shift Price is Required'),
    })
  ),
  requirement: Yup.array().of(
    Yup.object().shape({
      materials: Yup.array().min(1,"At least select one Material is Required").required('At least select one Material is Required'),
      consumables: Yup.array().min(1,"At least select one Consumables is Required").required('At least select one Consumables is Required'),
      equipments: Yup.array().min(1,"At least select one Equipment is Required").required('At least select one Equipment is Required'),
    })
  ),
  duration:Yup.array().of(
    Yup.object().shape({
      duration: Yup.number().required('Duration is Required'),
    })
  )
});

function CreateService() {
  const {pathname}= useLocation();
  const {service,id} = useParams();
  const navigate = useNavigate();

  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [serviceDataById, setServiceDataById] = useState([])
  const [categoryData, setCategoryData] = useState([]);
  const [subServiceCategory, setSubServiceCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [aircraftData, setAircraftData] = useState([]);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const [selectOptions, setSelectOptions] = useState({});

  // handle Aircraft Data Length
  const handleAircraftDetail = (value,initialValues,setFieldValues) => {
    if (value?.length <= 0){
      setFieldValues('aircraft_served',[])
      setFieldValues('price_variation',[]);
      setFieldValues('requirement',[]);
      setFieldValues('duration',[])
    } else if(value?.length > initialValues?.price_variation?.length){
      for (let index = 0; index < value?.length - initialValues?.price_variation?.length; index++) {
        setFieldValues('price_variation',[...initialValues?.price_variation,{aircraft_served_name:`${value[value?.length - 1]?.name}`,aircraft_served_id:`${value[value?.length - 1]?.id}`,day_shift_price:'', night_shift_price:''}]);
        setFieldValues('requirement',[...initialValues?.requirement,{aircraft_served_name:`${value[value?.length - 1]?.name}`,aircraft_served_id:`${value[value?.length - 1]?.id}`}]);
        setFieldValues('duration',[...initialValues?.duration,{aircraft_served_name:`${value[value?.length - 1]?.name}`,aircraft_served_id:`${value[value?.length - 1]?.id}`,duration:''}])
      }
    } else if (value?.length < initialValues?.price_variation?.length){
      const new_data = {...initialValues}
      for (let index = 0; index < initialValues?.price_variation?.length; index++) {
        if(!value?.map(e => e?.name)?.includes(initialValues?.price_variation[index]?.aircraft)){
          new_data?.price_variation?.splice(index, 1);
          new_data?.requirement?.splice(index, 1);
          new_data?.duration?.splice(index, 1);
        }
      }
      setFieldValues('price_variation',new_data?.price_variation);
      setFieldValues('requirement',new_data?.requirement);
      setFieldValues('duration',new_data?.duration)
    }
  }
  // *For Getting Equipment Types By ID
  const getMaterialsEquipmentTypesById = async (id) => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getMaterialsEquipmentTypesById(id)
      if (status === true && responseCode === 200) {
        return data
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Equipment Types
  const getMaterialsEquipmentsTypes = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getMaterialsEquipmentsTypes()
      if (status === true && responseCode === 200) {
        setEquipmentTypes(data)
        const values = {}
        for (let index = 0; index < data?.length; index++) {
          if(data[index]?.id){
            getMaterialsEquipmentTypesById(data[index]?.id).then((res) =>{
              const key = `${data[index]?.type?.toLowerCase()}`
              if(res && res?.length > 0){
                values[key] = [...res]
              } else { values[key] = []}
            })
          }
        }
        setSelectOptions(()=> {return values})
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Service Categories
  const getAllServiceCategories = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:'1000',page:'1',status:1})
      if (status === true && responseCode === 200) {
        setCategoryData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Sub Service Categories
  const getServiceSubCategoryByCategoryID = async (id) => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceSubCategoryByCategoryID(id)
      if (status === true && responseCode === 200) {
        setSubServiceCategory(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Aircraft
  const getAircraftServed = async ({limit:rowsPerPage,page}) => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftServed({limit:rowsPerPage,page})
      if (status === true && responseCode === 200) {
        setAircraftData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Service By Id
  const getServiceById = async (id) => {
    try {
      const requirementArray = []
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceById(id)
      if (status === true && responseCode === 200) {
        if(data?.serviceRequirements?.length > 0){
          for (let requirementIndex = 0; requirementIndex < data?.serviceRequirements[0]?.equipment_types?.length; requirementIndex++) {
            const requirementValue = {}
            for (let columnIndex = 0; columnIndex < data?.serviceRequirements.length; columnIndex++) {
              requirementValue[`aircraft_served_id`] = data?.serviceRequirements[columnIndex]?.equipment_types[requirementIndex]?.aircraft_served_id
              requirementValue[`aircraft_served_name`] = data?.serviceRequirements[columnIndex]?.equipment_types[requirementIndex]?.aircraft_served_name
              const columnValue = []
              for (let columnValueIndex = 0; columnValueIndex < data?.serviceRequirements[columnIndex]?.equipment_types[requirementIndex]?.aircraft_served_equipments?.length; columnValueIndex++) {
                const obj = {
                  service_requirement_id:data?.serviceRequirements[columnIndex]?.equipment_types[requirementIndex]?.aircraft_served_equipments[columnValueIndex]?.service_requirement_id,
                  id:data?.serviceRequirements[columnIndex]?.equipment_types[requirementIndex]?.aircraft_served_equipments[columnValueIndex]?.equipment_id,
                  name:data?.serviceRequirements[columnIndex]?.equipment_types[requirementIndex]?.aircraft_served_equipments[columnValueIndex]?.equipment_name,
                }
                columnValue?.push(obj)
              }
              requirementValue[`${data?.serviceRequirements[columnIndex]?.equipment_type_name?.toLowerCase()}`] = columnValue
            }
            requirementArray.push(requirementValue)
          }
        }
        setServiceDataById({...data,requirements:requirementArray});
        setSelectedCategoryId(data?.service_category_id);
        setDataLoading(false);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // handleSubmitService
  const handleSubmitService = async (values) => {
    try {
      setLoading(true);
      const aircraft_served_id = []
      const service_requirements = []
      for (let aircraftServedIdIndex = 0; aircraftServedIdIndex < values?.aircraft_served?.length; aircraftServedIdIndex++) {
        aircraft_served_id?.push(values?.aircraft_served[aircraftServedIdIndex]?.id)
      }
      for (let serviceRequirementIdIndex = 0; serviceRequirementIdIndex < values?.requirement?.length; serviceRequirementIdIndex++) {
        for (let columnIndex = 0; columnIndex < equipmentTypes?.length; columnIndex++) {
          for (let equipmentIndex = 0; equipmentIndex < values?.requirement[serviceRequirementIdIndex][equipmentTypes[columnIndex]?.type?.toLowerCase()]?.length; equipmentIndex++) {
            const requirementsArray = {
              aircraft_served_id:values?.requirement[serviceRequirementIdIndex]?.aircraft_served_id,
              // equipment_type_id:values?.requirement[serviceRequirementIdIndex][equipmentTypes[columnIndex]?.type?.toLowerCase()][equipmentIndex]?.equipment_type_id,
              equipment_type_id:equipmentTypes[columnIndex]?.id,
              equipment_id:values?.requirement[serviceRequirementIdIndex][equipmentTypes[columnIndex]?.type?.toLowerCase()][equipmentIndex]?.id,
            }
            service_requirements?.push(requirementsArray)
          }
        }
      }
      const newPriceVariation = []
      for (const priceDetails of values?.price_variation) {
        newPriceVariation.push({...priceDetails,day_shift_price: parseFloat(priceDetails?.day_shift_price.replace(/,/g, '')),
        night_shift_price:parseFloat(priceDetails?.night_shift_price.replace(/,/g, ''))})
      }
      const newValue = {
        name:values?.service_name,
        service_category_id:values?.service_category,
        service_subcategory_id:values?.sub_category,
        aircraft_served_id:aircraft_served_id,
        description:values?.description,
        service_price_variations:JSON.stringify(newPriceVariation),
        service_requirements:JSON.stringify(service_requirements),
        service_durations:JSON.stringify(values?.duration),
      }
      if(id){
        const { status, responseCode, message } = await ServiceCategoryServices.patchService({id:id,data:newValue  })
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await ServiceCategoryServices.postService(newValue)
        if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){
      setDataLoading(true);
      getServiceById(id);
    }
    getMaterialsEquipmentsTypes();
    getAllServiceCategories();
    getAircraftServed({limit:10000,page:1});
    if(selectedCategoryId){
      getServiceSubCategoryByCategoryID(selectedCategoryId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname,selectedCategoryId,id]);

  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          service_category: id ? serviceDataById?.service_category_id : '',
          sub_category: id ? serviceDataById?.service_subcategory_id : null,
          service_name:id ? serviceDataById?.service_name : '',
          description:id ? serviceDataById?.description : '',
          aircraft_served: id ? serviceDataById?.aircraftServeds : [],
          price_variation:id ? serviceDataById?.servicePriceVariation : [],
          requirement:id ? serviceDataById?.requirements : [],
          duration:id ? serviceDataById?.serviceDuration : []
        }}
        validationSchema={ServiceSchema}
        onSubmit={values => {handleSubmitService(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit' : ''} ${service}` : 'Create Services'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Manage Services',!id ? 'Create Service' : `${allowEdit ? 'Edit' : ''} Service` ]} />
          </Box>
          {dataLoading ? <Loader />: 
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <Box sx={{mb:1.5,display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                  <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Service Category</FormLabel>
                  <Button disabled={!allowEdit} startIcon={<Add />} sx={{fontSize:11, p:0, color:colors.primary,':hover':{backgroundColor:'transparent'}}}
                  onClick={() => navigate('category/add')} >Add New</Button>
                </Box>
                <Select name="service_category"  size="medium" disabled={!allowEdit}
                  value={values?.service_category ? values?.service_category : 0}
                  onChange={(e) => {setFieldValue(`service_category`, e?.target?.value);setSelectedCategoryId(e?.target?.value)}}
                  sx={{color:values?.service_category === '' ? colors.formLabel : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                  Select Category
                </MenuItem>
                {categoryData?.map((categoryItem,index) => (
                  <MenuItem key={index} value={categoryItem?.id}>{categoryItem?.name}</MenuItem>
                ))}
                </Select>
                {touched.service_category && errors.service_category && <CustomErrorBox>{errors.service_category}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <Box sx={{mb:1.5,display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Sub Category</FormLabel>
                <Button startIcon={<Add />} sx={{fontSize:11, p:0, color:colors.primary,':hover':{backgroundColor:'transparent'}}}
                  disabled= {!allowEdit ? !allowEdit : values?.service_category ? false : true}
                  onClick={() => navigate('sub-category/add')} >Add New</Button>
                </Box>
                <Select name="sub_category"  size="medium"  
                  value={values?.sub_category ? values?.sub_category : 0}
                  disabled= {!allowEdit ? !allowEdit : values?.service_category ? false : true}
                  onChange={(e) => {setFieldValue(`sub_category`, e?.target?.value)}}
                  sx={{color:values?.sub_category === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Sub Category
                  </MenuItem>
                  {subServiceCategory?.length > 0 ? subServiceCategory?.map((subCategoryItem,index) => (
                  <MenuItem key={index} value={subCategoryItem?.id}>{subCategoryItem?.sub_category_name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                {touched.sub_category && errors.sub_category && <CustomErrorBox>{errors.sub_category}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Add Service Name</FormLabel>
                <TextField disabled={!allowEdit}  name="service_name" size="medium" placeholder='Service Name'
                value={values?.service_name} onChange={(e) => {setFieldValue(`service_name`, e?.target?.value)}} />
                {touched.service_name && errors.service_name && <CustomErrorBox>{errors.service_name}</CustomErrorBox>}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Served</FormLabel>
                <AutocompleteInput disabled={!allowEdit} name="aircraft_served" placeholder='Aircraft Served' data={aircraftData} 
                  value={values?.aircraft_served} 
                  onChange={(e,value) => {setFieldValue('aircraft_served',value);handleAircraftDetail(value,values,setFieldValue)}} />
                  {touched.aircraft_served && errors.aircraft_served && <CustomErrorBox>{errors.aircraft_served}</CustomErrorBox>}
                </FormControl>
              </Grid>
              {/* Price Variation */}
              <Grid item xs={12} md={12}>
                {values?.price_variation?.length > 0 && 
                <PriceVariation data={values?.price_variation} value={values} setValue={setFieldValue} 
                touched={touched} errors={errors} disabled={!allowEdit} />}
              </Grid>
              {/* Requirement */}
              <Grid item xs={12} md={12}>
                {values?.requirement?.length > 0 && <Requirement data={values?.requirement} disabled={!allowEdit}
                value={values?.requirement} setValue={setFieldValue} columnsData={equipmentTypes} options={selectOptions} 
                errors={errors} touched={touched}/>}
              </Grid>
              {/* Duration */}
              <Grid item xs={12} md={12}>
                {values?.duration?.length > 0 && <Duration data={values?.duration} disabled={!allowEdit}
                value={values} setValue={setFieldValue} errors={errors} touched={touched} />}
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Service Description</FormLabel>
                <TextField disabled={!allowEdit}  name="description" placeholder="Service Description" size="medium" 
                multiline minRows={3} value={values?.description} 
                onChange={(e) => {setFieldValue(`description`, e?.target?.value)}} />
                {touched.description && errors.description && <CustomErrorBox>{errors.description}</CustomErrorBox>}
                </FormControl>
              </Grid>
            </Grid>
            {allowEdit && 
            <Box textAlign={'right'} my={2}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Box>}
          </Fragment>}
          <Routes>
            <Route path='category/add' element={<AddCategory />} />
            <Route path='sub-category/add' element={<AddSubCategory category={categoryData?.filter((e) => e?.id === values?.service_category)[0]?.name} categoryId={categoryData?.filter((e) => e?.id === values?.service_category)[0]?.id} />} />
          </Routes>
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default CreateService