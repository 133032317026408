import React from 'react';
import { LoadingButton } from '@mui/lab';
import { ArrowBackOutlined, ArrowForwardOutlined, CancelOutlined, Create, Done } from '@mui/icons-material';
// import colors from 'app/styles/colors';

export const CancelButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      startIcon={props.title === 'Back' ? <ArrowBackOutlined /> : <CancelOutlined />}
      sx={{
        color:'white',
        fontWeight:'bold',borderRadius:'8px',
        textTransform: 'capitalize',
        minWidth: '120px',py:1,
        backgroundColor: '#DC4444',
        ...props.sxstyle,
        "&:hover": { bgcolor: '#DC4444' }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const CreateButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      startIcon={props.title === 'Submit' ? <Done /> : props.title !== 'Next' ? <Create /> : null}
      endIcon={props.title === 'Next' && <ArrowForwardOutlined />}
      sx={{
        color:'white',
        fontWeight:'bold',borderRadius:'8px',
        textTransform: 'capitalize',
        minWidth: '120px',py:1,
        backgroundColor: '#008035',
        ...props.sxstyle,
        "&:hover": { bgcolor: '#008035' }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}