import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Add, Block, CheckCircleOutlineOutlined, Delete, MoreVert } from '@mui/icons-material';
import { Box, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { SecondaryButton } from 'app/components/Buttons/Button';
import colors from 'app/styles/colors';
import Loader from 'app/components/Loader/Loader';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import StatusChip from 'app/components/StatusChip/StatusChip';
import Layout from 'app/layout/Layout';
import UserServices from 'app/apis/User/UserServices';
// Library Import
import Swal from 'sweetalert2';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'name',label: 'User',align: 'left',clickAble: true},
  {id: 'email',label: 'Email',align: 'left',},
  {id: 'type',label: 'Type',align: 'left',},
  {id: 'status',label: 'Status',align: 'left',},
];

function UserManagement() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [rowDetails, setRowDetails] = useState({});
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // For Deleting User
  const deleteUserById = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this record!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText:'No',
      customClass: {
        container: 'overlay', // Apply the overlay style
        popup: 'swal-modal'   // Apply the modal style
    },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
        const { status, responseCode, message } = await UserServices.deleteUserById(id)
        if (status === true && responseCode === 200) {
          Swal.fire({
            title:'Success!',
            text:message,
            icon:'success',
            customClass: {
              container: 'overlay', // Apply the overlay style
              popup: 'swal-modal'   // Apply the modal style
            }})} 
            getUsers({limit:rowsPerPage,page:page,status:filter === 'active' ? '1' : filter === 'all' ? '' : '0'})
        } catch (error) {
          ErrorToaster(error)
        }
      }
    }) 
  }
  
  // For Suspending User
  const patchUserStatus = async (obj) => {
    try {
      const value = {id:obj?.id,data:{status:+obj?.status === 1 ? 0 : 1}}
      const { status, responseCode, message } = await UserServices.patchUserStatus(value)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        getUsers({limit:rowsPerPage,page:page,status:filter === 'active' ? '1' : filter === 'all' ? '' : '0'})
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Users
  const getUsers = async (obj) => {
    try {
      setLoading(true);
      const { status, responseCode, data, count } = await UserServices.getUsers(obj)
      if (status === true && responseCode === 200) {
        setCount(count);
        setUserData(data);
        setLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsers({limit:rowsPerPage,page:page,status:filter === 'active' ? '1' : filter === 'all' ? '' : '0'});
  }, [filter, page, rowsPerPage]);

  return (
    <Layout>
    <Box>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>User Management</Typography>
      <SecondaryButton title={"Create User"}  onClick={() => navigate('/create-user')} startIcon={<Add />} />
      </Box>
      <BreadCrumbs currentPage={['User Management']} />
      <Box sx={{border:'1.5px solid #008035', borderRadius:'8px',height:'45px',display:'flex',alignItems:"center",maxWidth:'max-content',boxSizing:'border-box',my:4,}}>
      {['All','Active','In Active'].map((item,index) => (
      <Box sx={{borderRight:index !== 2 ?'1.5px solid #008035' : '',height:'100%',width:'100px',alignItems:'center',cursor:'pointer',
      display:'flex',backgroundColor:filter?.toLowerCase()=== item?.toLowerCase() ? '#008035' : '',borderRadius:index === 0 ? '7px 0px 0px 7px' : index === 2 ? '0px 7px 7px 0px' :  ''}}
      onClick={() => setFilter(item?.toLowerCase())}>
      <Typography sx={{color:filter?.toLowerCase() !== item?.toLowerCase() ? '#008035' : 'white', fontWeight:'bold',mx:'auto'}}>{item}</Typography></Box>
      ))}
    </Box>
    {loading ? <Loader /> :
    <Fragment>
      {userData?.length > 0 ? (
      <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
        <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
          <Table className={classes?.table} stickyHeader aria-label="sticky table" >
              <TableHead >
                <TableRow >
                  {columns?.map((column) => (
                    <StyledTableCell
                      key={column?.id}
                      align={column?.align}
                      style={{ fontWeight:600 }}
                    >
                      {column?.label}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align={'center'} style={{ fontWeight:600 }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userData?.map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {columns?.map((column) => {
                        const value = row[column?.id];
                        return (
                          <Fragment key={index}>
                          {column?.id === 'status' ? (
                          <StyledTableCell align={column?.align}>
                            <StatusChip value={+value === 1 ? 'Active' : 'Suspended'} />
                          </StyledTableCell>
                          ) : (
                          <StyledTableCell align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                          onClick={() =>  {column.clickAble && navigate(`edit/${row?.name}/${row.id}`)}}>
                            {column.format && typeof value === 'number'
                              ? column?.format(value)
                              : value}
                          </StyledTableCell>
                          )}
                          </Fragment>
                        );
                      })}
                      <StyledTableCell align='center'>
                        <MoreVert sx={{color:colors.primary, cursor:'pointer'}} onClick={(event) => {setAnchorEl(event?.currentTarget);setRowDetails(row)}} />
                      </StyledTableCell>
                    </TableRow>
                  );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50,100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
      </Paper>) : (
        <Box
          display={'flex'}
          p={6.25}
          alignItems={'center'}
          // width="100%"
          justifyContent={'center'}
        >
          <Typography variant="h5" fontWeight={400} >
            No Data
          </Typography>
        </Box>
      )}
    </Fragment>}
    <Menu
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
      onClose={() => setAnchorEl(null)}
      PaperProps={{style: {width: '180px',borderRadius:'5px',}}}
    >
    <MenuItem sx={{'&:hover':{backgroundColor:'#008035',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}} 
      onClick={() => {setAnchorEl(null);patchUserStatus(rowDetails)}}>
      <Typography>{+rowDetails?.status === 1 ? 'Suspend' : 'Enable'}</Typography>
      {+rowDetails?.status === 1 ?  <Block fontWeight={'bold'} /> : <CheckCircleOutlineOutlined fontWeight={'bold'} />}
    </MenuItem>
    <MenuItem sx={{'&:hover':{backgroundColor:'#DC4949',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}}
      onClick={() => {setAnchorEl(null);deleteUserById(rowDetails?.id)}}>
      <Typography>Delete</Typography>
      <Delete fontWeight={'bold'} />
      </MenuItem>
    </Menu>
  </Box>
  </Layout>
  )
}

export default UserManagement