import Axios from '../../axios';
import AxiosTemp from '../../tempAxios';
import { ErrorHandler } from 'app/utils/ErrorHandler';

export const get = async (endPoint, param) => {
    try {
        const result = await Axios.get(endPoint, { params: param });
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const put = async (endPoint, data) => {
    try {
        const result = await Axios.put(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const post = async (endPoint, data) => {
    try {
        const result = await Axios.post(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const patch = async (endPoint, data) => {
    try {
        const result = await Axios.patch(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const deleted = async (endPoint, param) => {
    try {
        const result = await Axios.delete(endPoint, { params: param });
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

// temp routes
export const getTemp = async (endPoint, param) => {
    try {
        const result = await AxiosTemp.get(endPoint, { params: param });
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const putTemp = async (endPoint, data) => {
    try {
        const result = await AxiosTemp.put(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const postTemp = async (endPoint, data) => {
    try {
        const result = await AxiosTemp.post(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const patchTemp = async (endPoint, data) => {
    try {
        const result = await AxiosTemp.patch(endPoint, data);
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};

export const deletedTemp = async (endPoint, param) => {
    try {
        const result = await AxiosTemp.delete(endPoint, { params: param });
        return result.data;
    } catch (e) {
        throw ErrorHandler(e);
    }
};