const CustomerRoutes = {
  getCustomers: "/admin/customers",
  getCustomerById:"/admin/customerById/",
  getCustomerTypes:"/admin/customerTypes",
  deleteCustomerById:"/admin/customer/",
  postCustomer:"/admin/customer",
  patchCustomerById:"/admin/customer/",
  patchCustomerStatus:"/admin/customerStatus/",
  // Cities & Countries
  getCountries:"/countries",
  getCitiesByCountryId:"/citiesByCountryId/",
}
export default CustomerRoutes