import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Typography, Grid, Box, InputAdornment, IconButton, } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, } from 'react-router-dom';

// Import Components
import AuthServices from 'app/apis/Auth/AuthServices';
import Images from 'app/assets/Images';
import colors from 'app/styles/colors';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import { OutlineInputField } from 'app/components/UI/TextField';
import { PrimaryButton } from 'app/components/Buttons/Button';
import { emailRegex } from 'app/utils';

function ForgotPassword() {
  var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors },watch } = useForm();
  const [loading, setLoading] = useState(false);

  // *For Sending Code and Changing Password Toggle
  const [isEmailSend, setIsEmailSend] = useState('');
  // *For Show Password Toggle Icon
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // *Send verification Code
  const sendVerificationCode = async (data) => {
    setLoading(true)
    try {
      const { status, responseCode, message } = await AuthServices.sendVerificationCode(data)
      if (status === true && responseCode === 200) {
        setIsEmailSend(data?.email);
        SuccessToaster(message)
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  // *Forgot Password 
  const changePassword = async (data) => {
    setLoading(true)
    try {
      const obj = {...data,email:isEmailSend}
      const { status, responseCode, message } = await AuthServices.changePassword(obj)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        navigate('/login')
      }
    } catch (error) {
      ErrorToaster(error)
    } finally {
      setLoading(false)
    }
  };

  return (
  <Grid container alignItems={'center'} justifyContent={'center'} 
    sx={{ height: "100vh", backgroundImage: `url(${Images.plane})`,backgroundSize: 'cover',overflow:'auto',
      backgroundPosition: 'center',zIndex:2 }}>
      <Grid item container xs={12} md={12} sx={{height:'100%',backgroundColor: `rgba(0, 126, 53, 0.5)`}} alignItems={'center'} justifyContent={'center'}>
        <Grid item container xs={10} md={3.5} alignItems={'center'} 
          sx={{my:2,height:'max-content',borderRadius:10, backgroundColor:'rgba(90,153,123, 0.6)',backdropFilter: 'blur(27px)',boxShadow:'0px 0px 10px rgba(0, 0, 0, 0.2)'}}>
          <Grid item xs={12} md={12} mx={3}>
            <Box sx={{ textAlign: "center",borderRadius: 2,width: '100%', boxSizing: "border-box",p:2}}>
              <Box sx={{display:'flex',alignItems:'center', justifyContent: 'center',my: 2,flexDirection:'column'}}>
              <Typography variant="h4" sx={{ color: colors.white, fontWeight:'bold'}}>FORGOT PASSWORD</Typography>
              {!isEmailSend && <Typography variant="body1" sx={{ color: colors.white}}>A verification code will be send to your email.</Typography>}
              </Box>
              {!isEmailSend ? (
                <Typography component={"form"} onSubmit={handleSubmit(sendVerificationCode)}>
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mb:2,fontWeight:'bold'}}>Email</Typography>
                  <OutlineInputField
                    label="Email"
                    type={'email'}
                    error={errors?.email?.message}
                    register={register("email", {
                      required: 'Please enter your email.',
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email address',
                      }
                    })}
                  />
                  <PrimaryButton
                    title="Send OTP"
                    type='submit'
                    loading={loading}
                    fullWidth={true}
                    sxstyle={{backgroundColor:'#306E52',border:'2px solid white',py:1,borderRadius:3,my:3,
                    fontSize:18,fontWeight:'bold',"&:hover":{backgroundColor:'#306E52'}}}
                  />
                </Typography>
              ) : (
                <Typography component={"form"} onSubmit={handleSubmit(changePassword)}>
                <Box sx={{ mt: 2, display: "flex", flexDirection: 'column' }}>
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mt:2,fontWeight:'bold'}}>Verification Code</Typography>
                  <OutlineInputField
                    label="XXXXXX"
                    type={'number'}
                    error={errors?.code?.message}
                    register={register("code", {
                      required: 'Please enter your verification code.',
                      pattern: {
                        value: /^[0-9]{1,6}$/,
                        message: 'Please enter a valid 6-digit verification code',
                      }
                    })}
                  />
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mt:2,fontWeight:'bold'}}>Password</Typography>
                  <OutlineInputField
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          sx={{ color: colors.springGreen, fontSize: "18px" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password?.message}
                    register={register("password", {
                      required: 'Please enter password.',
                      validate: (val) => {
                        if(val.length < 8){
                          return "Passwords should be of 8 characters"
                        } else if( !format.test(val)){
                          return "Passwords must includes special character";
                        } else if( !/[A-Z]/.test(val)){
                          return "Passwords must includes uppercase";
                        } else if( !/[a-z]/.test(val)){
                          return "Passwords must includes lowercase";
                        } else if( !/[0-9]/.test(val)){
                          return "Passwords must includes number";
                        }
                      }
                    })
                    }
                  />
                  <Typography variant='body1' sx={{color:'#FFFFFF', textAlign:'left', mt:2,fontWeight:'bold'}}>Confirm Password</Typography>
                  <OutlineInputField
                    label="Confirm Password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                          sx={{ color: colors.springGreen, fontSize: "18px" }}
                        >
                          {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    error={errors?.password_confirmation?.message}
                    register={register("password_confirmation", {
                      required: 'Please enter confirm password.',
                      validate: (val) => {
                        if (watch('password') != val) {
                          return "Passwords do no match";
                        }
                      }
                    })
                    }
                  />
                </Box>
                <PrimaryButton
                  title="Reset"
                  type='submit'
                  loading={loading}
                  fullWidth={true}
                  sxstyle={{backgroundColor:'#306E52',border:'2px solid white',py:1,borderRadius:3,my:3,
                  fontSize:18,fontWeight:'bold',"&:hover":{backgroundColor:'#306E52'}}}
                />
              </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;