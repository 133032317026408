import { get, post, patch, deleted } from 'app/apis';
import TeamsRoutes from './Teams.Routes';

const TeamServices = {
  getTeams: async (obj) => {
    const data = await get(TeamsRoutes.getTeams+`?limit=${obj?.limit}&page=${obj?.page}`);
    return data;
  },
  getTeamById: async (id) => {
    const data = await get(TeamsRoutes.getTeamById+`${id}`);
    return data;
  },
  deleteTeamById: async (id) => {
    const data = await deleted(TeamsRoutes.deleteTeamById+`${id}`);
    return data;
  },
  deleteTeamEmployeeById: async (obj) => {
    const data = await deleted(TeamsRoutes.deleteTeamEmployeeById+`${obj?.team_id}/${obj?.emp_id}`);
    return data;
  },
  postTeam: async (obj) => {
    const data = await post(TeamsRoutes.postTeam,obj);
    return data;
  },
  patchTeamById: async (obj) => {
    const data = await patch(TeamsRoutes.patchTeamById+`${obj.id}`,obj.data);
    return data;
  },
  patchTeamStatus: async (obj) => {
    const data = await patch(TeamsRoutes.patchTeamStatus+`${obj.id}`,obj.data);
    return data;
  },
}

export default TeamServices