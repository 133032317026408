import React, { Fragment, memo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Box, Typography, Badge, Avatar, styled } from '@mui/material';
import { ExpandLess, ExpandMore,Sort, } from '@mui/icons-material';

// *Imported Components
import colors from 'app/styles/colors';
import Images from 'app/assets/Images';
import Navigation from 'app/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { setHamburger } from 'app/views/slice/slice';
import useAuth from 'app/hooks/useAuth';
import { getCookie } from 'app/hooks/useCookie';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const drawerWidth = 300;

function SideNav() {
  // const { name } = useAuth();
  const { pathname } = useLocation();
  const {name,logo} = getCookie('userinfo')
  // Redux State to Open Close Hamburger
  const hamburger = useSelector((state) => state.slicer.hamburger);
  const dispatch = useDispatch();
  // const { role } = useAuth();
  const role = 'admin'

  const [expand, setExpand] = useState([]);

  // *For Collapse
  const handleCollapse = (value) => {
    const currentIndex = expand.indexOf(value);
    const newExpand = [...expand];

    if (currentIndex === -1) {
      newExpand.push(value);
    } else {
      newExpand.splice(currentIndex, 1);
    }

    setExpand(newExpand);
  };

  // *For Active
  const handleSubChildActive = (item) => {
    return (item.childPath?.indexOf(pathname) !== -1 && item.childPath?.indexOf(pathname))
  }

  // For Active Nested Sub Child not having a tag in Navigation Bar
  const handleNestedChild = (item) => {
    for (let index = 0; index < item?.childPath?.length; index++) {
     if(pathname?.split('/')?.includes(item?.childPath[index]?.split('/')[1])){
       return true
      } 
    }
  }

  
  return (
      <Box
        sx={{width: hamburger ? drawerWidth : 90,borderRadius:'10px',backgroundColor: colors.white,transitionTimingFunction:'ease-in-out',transition: 'width 0.75s',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;',mr:3,
          overflow:'auto','::-webkit-scrollbar': {display:'none'},position:'relative'
        }}>
      <Fragment>
        <Box sx={{textAlign:!hamburger ? 'center' : 'right', mt:2, mr:hamburger ? 2 : 0, cursor:'pointer',transitionTimingFunction:'ease-in-out',transition: 'width 0.75s'}} 
        onClick={() => dispatch(setHamburger(!hamburger))}>
          <Sort sx={{fontSize:30}} />
        </Box>
        {/* <Box sx={{ display: !hamburger ? 'none' : 'block', p: 1, textAlign: 'center', mb: 1, boxSizing:'border-box',transition:'0.4s ease-in-out all' }}>
        <img src={Images.logo} width={'80%'} alt="Logo" style={{transition:'0.5s ease-in-out all'}} />
        </Box> */}
        <Box sx={{textAlign:'center',my:3,transition:'0.4s ease-in-out all'}}>
        <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"> 
            <Avatar alt="User Profile" src={logo !== 'null' && logo !== null ? logo : null} 
            sx={{width:hamburger ? '120px': '70px',height:hamburger ?'120px': '70px',transition:'0.4s ease-in-out all',
            backgroundColor:colors.springGreen,textTransform:'uppercase'}} >
            {name[0]+name[1]} </Avatar>
          </StyledBadge>
          {hamburger &&
          <Fragment>
            <Typography sx={{color:colors.primary, fontWeight:'bold',mt:2,textTransform:'capitalize'}}>{name}</Typography>
            <Typography sx={{color:colors.formLabel, fontWeight:'bold'}}>Admin</Typography>
          </Fragment>}
        </Box>
        <List sx={{ px: 2, flexGrow: 1, maxHeight: 'unset',mb:4}}>
          {Navigation.map((item, index) => {
            const check = (item.path === pathname  || handleSubChildActive(item) || handleSubChildActive(item) === 0 || handleNestedChild(item))
            if(role === item.type || item.type === ""){
            return(
              <Fragment key={`Main-${index}`}>
                <ListItemButton
                  component={item.path ? Link : 'div'}
                  to={item.path ? item.path : ''}
                  onClick={() => item.childPath && handleCollapse(item.label)}
                  sx={{
                    borderRadius: 1,mt: 1.5,gap:2,
                    bgcolor: check ? colors.primaryLight : "",
                    // '&:hover': {
                    //   bgcolor: colors.primaryLight,
                    //   color: colors.springGreen,
                    //   ".MuiTypography-root": {
                    //     color: colors.springGreen
                    //   },
                    //   ".MuiListItemIcon-root": {
                    //     color: colors.springGreen
                    //   }
                    // }
                  }}
                >
                  <ListItemIcon sx={{
                    color:colors.springGreen,
                    minWidth: 30, fontSize: "24px",justifyContent:'center',
                  }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: hamburger ? 1 : 0,transition:'0.4s ease-in-out all', height:30 }} primary={
                  <Typography sx={{
                    fontSize: 14, fontWeight: check ? "bold" : "normal",mt:0.5,
                    color: check ? colors.springGreen : colors.textSecondary
                  }}>{item.label}</Typography>} />
                  {item?.children &&
                    ((expand.indexOf(item.label) !== -1 || check) ?
                      <ExpandLess sx={{opacity: hamburger ? 1 : 0,transition:'0.4s ease-in-out all', color: colors.textSecondary, }} /> : 
                      <ExpandMore sx={{opacity: hamburger ? 1 : 0,transition:'0.4s ease-in-out all', color: colors.textSecondary, }} />)
                  }
                </ListItemButton>
                {item?.children  &&
                  <Collapse in={expand.indexOf(item.label) !== -1 || item.childPath?.indexOf(pathname) !== -1 || check ? true : false} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding >
                      {item.children.map((child, i) => (
                        <Fragment key={`Sub-${i}`}>
                          <ListItemButton 
                            component={child.path ? Link : 'div'}
                            to={child.path ? child.path : ''}
                            onClick={() => child.path && handleCollapse(child.label)}
                            sx={{
                              ml: hamburger ? 2 : 0,px:'auto',
                              borderRadius: 1,
                              mt: 1.5,
                              // '&:hover': {
                              //   color: colors.springGreen,
                              //   bgcolor: colors.white,
                              //   ".MuiListItemIcon-root ": {
                              //     color: colors.springGreen
                              //   }
                              // }
                            }}
                          >
                            <ListItemIcon sx={{ color: (child.path === pathname || pathname.split('/').includes(child.path.split('/')[1])) ? colors.springGreen : colors.textSecondary, 
                              minWidth: 30, '.MuiSvgIcon-root': { fontSize: "18px" },justifyContent:'center', }}>
                              {child.icon}
                            </ListItemIcon>
                            <ListItemText sx={{opacity: hamburger ? 1 : 0,transition:'0.4s ease-in-out all', height:20}} primary={
                            <Typography sx={{
                              fontSize: 14,
                              fontWeight: child.path === pathname ? "bold" : "normal",
                              color: (child.path === pathname || pathname.split('/').includes(child.path.split('/')[1])) ? colors.springGreen : colors.textSecondary
                            }}>{child.label}</Typography>} />
                            {child?.children &&
                              (expand.indexOf(item.label) !== -1 ? <ExpandLess sx={{ color: colors.textSecondary }} /> : <ExpandMore sx={{ color: colors.textSecondary }} />)
                            }
                          </ListItemButton>
                        </Fragment>
                      ))}
                    </List>
                  </Collapse>
                }
              </Fragment>
              )}
            else {
              return(" ")
            }
          })}
        </List>
      </Fragment>
      <Box sx={{position:'absolute',bottom:10,textAlign:'center',width:'100%'}}>
        <Typography sx={{color:colors.labelColor, textAlign:'center',display:hamburger ? 'default': 'none',transition:'0.4s ease-in-out all',
        fontSize:14}}>@{new Date().getFullYear()} Jetglow. All Rights Reserved.</Typography>
      </Box>
    </Box>
  
  );
}

export default memo(SideNav);