import { Add, Block, CheckCircleOutlineOutlined, Delete, MoreVert } from '@mui/icons-material';
import { Box, Chip, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AircraftServices from 'app/apis/Aircraft/AircraftServices';
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { SecondaryButton } from 'app/components/Buttons/Button';
import { AutocompleteInput } from 'app/components/Input/Autocomplete';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
// Library Import
import Swal from 'sweetalert2';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id:'service_name', label:'Service', align:'left',clickAble: true},
  {id: 'service_category_name',label: 'Category',align: 'left',},
  {id: 'service_subcategory_name',label: 'Sub Category',align: 'left',},
  {id:'status', label:'Status'}
];

function ServiceListing() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [selectedAircraftTypes, setSelectedAircraftTypes] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [serviceRowDetails, setServiceRowDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *For Getting Aircraft
  const getAircraftServed = async () => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftServed({limit:1000,page:1})
      if (status === true && responseCode === 200) {
        setAircraftTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Service listing
  const getServiceListing = async (rowsPerPage,page) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,count } = await ServiceCategoryServices.getServiceListing({limit:rowsPerPage,page:page,
        aircraftTypes:selectedAircraftTypes?.length > 0 ? selectedAircraftTypes?.map((e)=> e?.id) : []})
      if (status === true && responseCode === 200) {
        setServiceData(data);
        setCount(count);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  // For Suspending Sub Service
  const patchServiceStatus = async (obj) => {
    try {
      const value = {id:obj?.id,data:{status:obj?.status === 1 ? 0 : 1}}
      const { status, responseCode, message } = await ServiceCategoryServices.patchServiceStatus(value)
      if (status === true && responseCode === 200) {
        SuccessToaster(message);
        getServiceListing(rowsPerPage,page)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  

  // For Deleting Category Service
  const deleteServiceById = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this record!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText:'No',
      customClass: {
        container: 'overlay', // Apply the overlay style
        popup: 'swal-modal'   // Apply the modal style
    },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
        const { status, responseCode, message } =  await ServiceCategoryServices.deleteService(id)
        if (status === true && responseCode === 200) {
          Swal.fire({
            title:'Success!',
            text:message,
            icon:'success',
            customClass: {
              container: 'overlay', // Apply the overlay style
              popup: 'swal-modal'   // Apply the modal style
            }})} 
            getServiceListing(rowsPerPage,page)
        } catch (error) {
          ErrorToaster(error)
        }
      }
    }) 
  }

  useEffect(() => {
    getAircraftServed();
  }, [])
  

  useEffect(() => {
    getServiceListing(rowsPerPage,page);
  }, [page, rowsPerPage,selectedAircraftTypes]);

  return (
    <Layout>
    <Box>
      <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
      <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>Manage Services</Typography>
      <SecondaryButton title={"Create New"}  onClick={() =>navigate('/create-service')} startIcon={<Add />} />
      </Box>
      <BreadCrumbs currentPage={['Manage Services','Services']} />
      <Box my={2.5} width={'30%'}>
        <AutocompleteInput name="aircraft_served" placeholder='Select Aircraft Served' data={aircraftTypes} 
          value={selectedAircraftTypes} 
          onChange={(_,value) => {setSelectedAircraftTypes(value)}} />
        </Box>
      {isLoading ? <Loader /> :
      <Fragment>
        {serviceData?.length > 0 ?
        <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
          <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
          <Table className={classes.table} stickyHeader aria-label="sticky table" >
              <TableHead >
                <TableRow >
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ fontWeight:600 }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align={'center'} style={{ fontWeight:600 }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {serviceData?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <Fragment key={index}>
                          {column.id === 'status' ? (
                          <StyledTableCell key={column.id} align={column.align}>
                            <StatusChip value={value === 1 ? 'Active' : 'Suspended'} />
                          </StyledTableCell>
                          ) : (
                          <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                          onClick={() =>  {column.clickAble && navigate(`/edit-service/${row?.service_name}/${row?.id}`)}}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value ?? '--'}
                          </StyledTableCell>)}
                          </Fragment>
                        );
                      })}
                      <StyledTableCell align='center'>
                        <MoreVert sx={{color:colors.primary, cursor:'pointer'}} onClick={(event) => {setAnchorEl(event.currentTarget);setServiceRowDetails(row)}} />
                      </StyledTableCell>
                    </TableRow>
                  );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50,100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper> :
        <Box
          display={'flex'}
          p={6.25}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Typography variant="h5" fontWeight={400} >
            No Data
          </Typography>
        </Box>}
      </Fragment>}
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
        onClose={() => setAnchorEl(null)}
        PaperProps={{style: {width: '180px',borderRadius:'5px',}}}
      >
       
        <MenuItem sx={{'&:hover':{backgroundColor:'#008035',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}} 
         onClick={() => {setAnchorEl(null);patchServiceStatus(serviceRowDetails)}}>
          <Typography>{+serviceRowDetails?.status === 1 ? 'Suspend' : 'Enable'}</Typography>
          {+serviceRowDetails?.status === 1 ?  <Block fontWeight={'bold'} /> : <CheckCircleOutlineOutlined fontWeight={'bold'} />}
         </MenuItem>
        <MenuItem sx={{'&:hover':{backgroundColor:'#DC4949',color:'white','.MuiSvgIcon-root':{color:'white'}},display:'flex', alignItems:'center', justifyContent:'space-between',py:1}}
        onClick={() => {setAnchorEl(null);deleteServiceById(serviceRowDetails?.id)}}>
          <Typography>Delete</Typography>
          <Delete fontWeight={'bold'} />
        </MenuItem>
      </Menu>
    </Box>
    </Layout>
  )
}

export default ServiceListing