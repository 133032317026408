import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { KeyboardArrowDown } from '@mui/icons-material';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function AsyncAutocomplete ({name,data, label, placeholder, limitTag, onChange }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      name = {name}
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onChange}
      multiple
      limitTags={limitTag ? limitTag : 3}
      isOptionEqualToValue={(option, value) => option?.name === value?.name}
      getOptionLabel={(option) => option?.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default AsyncAutocomplete;

export const AutocompleteInput = ({name,data, label, placeholder, limitTag,value=[] ,onChange,multiple = true, disabled,identifier ='name',identifier_2=''  }) => {
  return (
    <Autocomplete
      id="synchronous-demo"
      name = {name}
      sx={{ width: '100%',bgcolor:'white' }}
      popupIcon={<KeyboardArrowDown />}
      disabled={disabled}
      disableCloseOnSelect={multiple ? multiple : false}
      value={multiple ? [...value] : value}
      onChange={onChange}
      multiple={multiple}
      limitTags={limitTag ? limitTag : 3}
      isOptionEqualToValue={(option, value) => option[identifier] === value[identifier]}
      getOptionLabel={(option) => identifier_2 !== '' ? option[identifier]+' ( Price $'+option[identifier_2]+' )' : option[identifier]}
      options={data}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}