import React from 'react';
import { LoadingButton } from '@mui/lab';
// import colors from 'app/styles/colors';

export const PrimaryButton = (props) => {
  return (
    <LoadingButton
      variant="contained"
      {...props}
      sx={{
        boxSizing:'border-box',
        textTransform: 'capitalize',
        minWidth: '100px',py:1,
        ...props.sxstyle
      }}
    >
      {props.title}
    </LoadingButton>
  );
}

export const SecondaryButton = (props) => {
  return (
    <LoadingButton
      variant="outlined"
      {...props}
      sx={{
        color:'#646d81',
        fontWeight:'bold',borderRadius:'8px',
        textTransform: 'capitalize',
        border:'2px solid #cbcfd9',
        boxSizing:'border-box',
        minWidth: '100px',py:1,
        // backgroundColor: colors.secondary,
        ...props.sxstyle
        // "&:hover": { bgcolor: colors.secondaryDark }
      }}
    >
      {props.title}
    </LoadingButton>
  );
}