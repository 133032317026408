import React from 'react'
import { FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import MultiListingSelect from 'app/components/Input/MultiListingSelect';
import colors from 'app/styles/colors';
import { Error } from 'app/components/UI/Error';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'white',
      color: colors.secondaryDark,
      fontSize:18, fontWeight:600,textTransform:'capitalize',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,width:'25%',textTransform:'capitalize',
    },
  }));
  
function Requirement({data,value,setValue,errors,touched,disabled,columnsData=[],options}) {
  const handleValue = (selectValue,setFieldValue,key) => {
    const select = []
    for (let index = 0; index < selectValue.length; index++) {
      const check = selectValue?.filter((e) => e?.id === selectValue[index]?.id)
      if(check?.length === 1){
        select?.push(check[0])
      } 
    }
    setFieldValue(key,select)
  }

  return (
    <TableContainer component={Paper} sx={{my:2}}>
      <Table sx={{ minWidth: 700,"& .MuiTableCell-root": {
      border: '1px solid #ddd',
    } }} >
        <TableHead>
          <StyledTableCell align="left" colSpan={4} style={{backgroundColor:'#eee'}}>Service Requirements</StyledTableCell>
          <TableRow>
            <StyledTableCell align="center">Aircraft</StyledTableCell>
            {columnsData?.map((column) =>(
              <StyledTableCell key={column?.id} align="center">{column?.type}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody >
          {data?.map((row,index) => (
            <TableRow key={index} >
              <StyledTableCell align="center" >{row?.aircraft_served_name}</StyledTableCell>
              {columnsData?.map((column,typeIndex) => (
                <StyledTableCell key={column?.id} align="center" style={{verticalAlign:'baseline'}}>
                <FormControl fullWidth >
                  <MultiListingSelect value={value[index][`${column?.type?.toLowerCase()}`]} 
                    onChange={(e,value) => {handleValue(value,setValue,`requirement[${index}][${column?.type?.toLowerCase()}]`);}}
                    identifier={'name'} data={options[column?.type?.toLowerCase()] ? options[column?.type?.toLowerCase()] : []} disabled={disabled}/>
                  {
                  // Ensure requirement, errors, column, and index are defined and column.type is a string
                  touched?.requirement &&
                  errors?.requirement &&
                  column &&
                  typeof column?.type === 'string' &&
                  index !== undefined && (
                    // Determine the error message based on column type
                    <Error
                      error={
                        column?.type?.toLowerCase() === 'materials'
                          ? errors?.requirement?.[index]?.materials
                          : column?.type?.toLowerCase() === 'consumables'
                          ? errors?.requirement?.[index]?.consumables
                          : errors?.requirement?.[index]?.equipments
                      }
                    />
                  )
                }
                </FormControl>
              </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Requirement