import { Fragment, useEffect, useState } from 'react';
import { Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import moment from 'moment';
// Service Import
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';
// Component Import
import colors from 'app/styles/colors'
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import Loader from 'app/components/Loader/Loader';
import { CustomErrorBox } from './CreateJobOrder';

function TimeSlot({values,errors,isClicked,setFieldValue}) {
  const [isLoading, setisLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  // *For Getting Service Category
  const getTimeSlot = async (date) => {
    try {
      setisLoading(true);
      const { status, responseCode, data } = await JobOrderServices.getTimeSlot(date)
      if (status === true && responseCode === 200) {
        setTimeSlots(data)
        setisLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setisLoading(false)
    }
  }
  useEffect(() => {
    getTimeSlot(values?.date);
  }, [values?.date])
  
  return (
    <Fragment>
      {isLoading  ? <Loader /> :
      <FormControl fullWidth >
        <RadioGroup name="time"
          value={JSON.stringify(values?.time)}
          onChange={(e,v) => {setFieldValue('time',JSON.parse(v))}}
          >
          <Grid container spacing={3} flex={1}>
            <Grid xs={12} md={12}>
              <Typography sx={{width:'max-content', float:'right',fontSize:18, fontWeight:'bold'}}>{moment(values?.date)?.format('dddd DD, MMMM YYYY')}</Typography>
            </Grid>
            {timeSlots?.map((time,index) => (
              <Grid key={`Time-${index}`} item xs={12} md={3}>
                <Box sx={{boxShadow: `rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;`,
                backgroundColor:time?.is_available ?  'white':'#8080802e',
                  cursor:time?.is_available ? 'pointer' : 'not-allowed' ,p:2,borderRadius:'10px'}} onClick={() => time?.is_available && setFieldValue('time',time)}>
                  <Box sx={{display:'flex',flexDirection:'row',gap:1}}>
                    <FormControlLabel value={timeSlots[0]} 
                      control={<Radio size='small' sx={{py:0}} value={JSON.stringify(time)} disabled={!time?.is_available} 
                      checked={!time?.is_available || values?.time?.start_time === time?.start_time}/>} />
                    <Typography variant='h5' fontWeight={600} sx={{color:colors.primaryDark}}>Slot {index+1}</Typography>
                    </Box>
                  <Box sx={{display:'flex',flexDirection:'row',alignItems:'flex-start',justifyContent:'center',gap:2}}>
                    <Box>
                      <Typography fontWeight={600} sx={{color:colors.waterBlue}}>Start Time</Typography>
                      <Typography >{time?.start_time}</Typography>
                    </Box>
                    <Divider sx={{bgcolor:colors.cloud,py:3}} orientation='vertical' />
                    <Box>
                      <Typography fontWeight={600} sx={{color:colors.waterBlue }}>End Time</Typography>
                      <Typography >{time?.end_time}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {isClicked && errors?.time?.start_time && <CustomErrorBox style={{marginTop:'12px'}}>{errors?.time?.start_time}</CustomErrorBox>}
        </RadioGroup>
      </FormControl>}
    </Fragment>
  )
}

export default TimeSlot