import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Step, StepLabel, Stepper, Typography, styled } from "@mui/material";
import { EditTwoTone } from "@mui/icons-material";
// Formik Import
import { Form, Formik } from "formik";
import * as Yup from 'yup';
// Service Import
import JobOrderServices from "app/apis/JobOrder/JobOrderService";
import ServiceCategoryServices from "app/apis/ServiceCategory/ServiceCategoryServices";
import CustomerServices from "app/apis/Customer/CustomerServices";
import MaterialsEquipmentsServices from "app/apis/MaterialsEquipments/MaterialsEquipmentsServices";
// Component Import
import colors from "app/styles/colors";
import Layout from "app/layout/Layout";
import { ErrorToaster, SuccessToaster } from "app/components/Toaster/Toaster";
import ArrowBackButton from "app/components/Buttons/ArrowBackButton";
import BreadCrumbs from "app/components/Breadcrumbs/Breadcrumbs";
import BasicInformation from "./BasicInformation";
import SelectService from "./SelectService";
import TimeSlot from "./TimeSlot";
import JobOrderSummary from "./JobOrderSummary";
import { SecondaryButton } from "app/components/Buttons/Button";
import { CancelButton, CreateButton } from "app/components/Buttons/ActionButtons";
import Loader from "app/components/Loader/Loader";

export const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const steps = ['Basic Information', 'Services', 'Time Slots','Summary'];

const CreatejobOrder =() => {
  const navigate = useNavigate();
  const {id='',order_number=''} = useParams();

  const [submit, setSubmit] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [click, setClick] = useState(false);
  const [allowEdit, setAllowEdit] = useState(id ? false : true);
  const [loading, setLoading] = useState(false);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [serviceDataOptions, setServiceDataOptions] = useState([]);
  const [jobOrderDetails, setJobOrderDetails] = useState('');
  const [stations, setStations] = useState([]);
  
  const [customers, setCustomers] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [aircraftEngineers, setAircraftEngineers] = useState([]);
  const [aircraftDetails, setAircraftDetails] = useState({tail_number:'',msn_number:''})

  // loader
  const [isLoading, setIsLoading] = useState(false);

  // schema
  const CreateJobOrderSchema = Yup.object().shape({
    customer_id: Yup.number().required('Customer field is Required'),
    // supervisor_id: Yup.number().required('Supervisor field is Required'),
    aircraft_id: Yup.number().required('Aircraft Type field is Required'),
    // aircraft_engineer_id: Yup.number().required('Aircraft Engineer field is Required'),
    date: Yup.string().required('Date field is Required'),
    time: Yup.object().shape({
      start_time:Yup.string().required('Time field is Required')
    }),
    station_id:Yup.number().required('Station field is Required'),
    // apron:Yup.string().required('Apron field is Required'),
    services:Yup.array().of(Yup.object().shape({
      service_category_id: Yup.number().required('Service Category field is Required'),
      services: Yup.array().of(Yup.object().shape({
        service_id: Yup.number().required('Service is Required'),
        })).min(1,'At leas One Service is Required'),
      })),
  }); 

  const handleNextBtn = (values) => {
    if(activeStep === 0){
      if(values?.customer_id && values?.aircraft_id && values?.date && values?.station_id){
        return true
      } return false
    } else if(activeStep === 1){
      if(values?.services?.length > 0){
        for (let serviceIndex = 0; serviceIndex < values?.services.length; serviceIndex++) {
        if(!values?.services[serviceIndex]?.service_category_id){
          for (let serviceIdIndex = 0; serviceIdIndex < values?.services[serviceIndex]?.services?.length; serviceIdIndex++) {
            if(!values?.services[serviceIndex]?.services[serviceIdIndex]?.service_id){
              return false
            }
          }
          return false
        }
        }}
      return true
    } else if(activeStep === 2){
      if(values?.time){
        return true
      } return false
    }
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setClick(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCategory = (value, index) => {
    setServiceDataOptions(prevArray => {
      if (index >= prevArray?.length) {
        const newArray = [...prevArray];
        newArray.length = index + 1;
        const updatedArray = [...newArray];
        updatedArray[index] = value;
        return updatedArray;
      }
      else{
        const updatedArray = [...prevArray];
        updatedArray[index] = value;
        return updatedArray
      }
    });
  }

  // Submit Create Order
  const handleSubmitJobOrder = async (obj) => {
    try {
      if(submit){
        const newData = {...obj,start_time:obj?.time?.start_time,end_time:obj?.time?.end_time, services: JSON.stringify(obj.services)}
        delete newData?.time
        setLoading(true)
        if(id){
          const { status, responseCode, message } = await JobOrderServices.updateJobOrder(id,newData)
          if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate('/view-job-order')
          setLoading(false)
          }
        } else {
          const { status, responseCode, message } = await JobOrderServices.postJobOrderDetails(newData)
          if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate('/view-job-order')
          setLoading(false)
          }
        }
      }
    } catch (error) {
      setLoading(false)
      ErrorToaster(error)
    }
  }

  // *For Getting Customers 
  const getCustomers = async () => {
    try {
      const { status, responseCode, data } = await CustomerServices.getCustomers({limit:1000,page:1,status:1})
      if (status === true && responseCode === 200) {
        setCustomers(data);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Stations
  const getStations = async () => {
    try {
      const { status, responseCode, data } = await MaterialsEquipmentsServices.getStations()
      if (status === true && responseCode === 200) {
        setStations(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Service Category
  const getServicesCategory = async () => {
    try {
      const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategories({limit:10000,page:1,status:1})
      if (status === true && responseCode === 200) {
        setServiceCategories(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Customers By Id
  const getCustomerById = async (id) => {
    try {
      const { status, responseCode, data} = await CustomerServices.getCustomerById(id)
      if (status === true && responseCode === 200) {
        if(data?.fleets?.length > 0){
          setAircraft(data?.fleets)
        }
        if(data?.aircraft_engineers?.length > 0){
          setAircraftEngineers(data?.aircraft_engineers);
        }
      }
      return data
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Services By Category Id And Aircraft Id
  const getServiceByCategoryAndAircraftId = async (obj,indexValue) => {
    try {
      const { status, responseCode, data} = await ServiceCategoryServices.getServiceByCategoryAndAircraftId(obj)
      if (status === true && responseCode === 200) {
        handleCategory(data,indexValue)
      }
      return data
    } catch (error) {
      handleCategory([],indexValue);
      ErrorToaster(error);
      return []
    }
  }

  // getting job order by ID
  const getJobOrderForEditById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data} = await JobOrderServices.getJobOrderForEditById(id)
      if (status === true && responseCode === 200) {
        setJobOrderDetails(data);
        setIsLoading(false);
      }
      return data
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCustomers();
    getServicesCategory();
    getStations();
    if(id){
      getJobOrderForEditById(id).then((res) => {
        if(res){
          getCustomerById(res?.customer_id).then((customerRes) => {
            if(customerRes?.fleets && customerRes?.fleets?.length > 0){
              const details = customerRes?.fleets?.find((e) => e?.aircraft_served_id === res?.aircraft_id)
              setAircraftDetails(() => {return {tail_number:details?.tail_number,msn_number:details?.msn_number}})
            }
          });
          for (let jobDetailsIndex = 0; jobDetailsIndex < res?.services?.length; jobDetailsIndex++) {
            getServiceByCategoryAndAircraftId({categoryId:res?.services[jobDetailsIndex]?.service_category_id,aircraftId:res?.aircraft_id,time:res?.time},jobDetailsIndex)
          }
        }
      })}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id]);


  return (
    <Layout>
      {isLoading ? <Loader /> :
      <Formik
        enableReinitialize
        initialValues={{
          customer_id: id ? jobOrderDetails?.customer_id : '',
          supervisor_id:id ? jobOrderDetails?.supervisor_id : '',
          aircraft_id:id ? jobOrderDetails?.aircraft_id : '',
          aircraft_engineer_id:id ? jobOrderDetails?.aircraft_engineer_id : '',
          date:id ? jobOrderDetails?.date : '',
          time:id ? {start_time:jobOrderDetails?.start_time,end_time:jobOrderDetails?.end_time,is_available:true} : '',
          apron:id ? jobOrderDetails?.apron :'',
          station_id:id ? jobOrderDetails?.station_id : '',
          services:id ? jobOrderDetails?.services : [{service_category_id: '',service_category_name:'',services:[]}],
        }}
       validationSchema={CreateJobOrderSchema}
       onSubmit={(values) => {handleSubmitJobOrder(values)}}
     >
      {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
            <Box sx={{display:'flex', alignItems:'center'}}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{id ? `${allowEdit ? 'Edit' : ''} Job Order # ${order_number}` : 'Create Job Order'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Job Orders',`${!id ? 'Create' : allowEdit ? 'Edit' : ''} Job Order`]} />
          </Box>
          <Box sx={{backgroundColor:colors.white, borderRadius: '10px',
            boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',p:3 }}>
            <Stepper activeStep={activeStep} sx={{my:2}}>
              {steps.map((label, index) =>( 
                <Step key={`${index}-${label}`} >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Fragment>
              <Typography my={2} fontWeight={600} variant="h5" sx={{color:colors.primary}}>{steps[activeStep]}</Typography>
              {activeStep === 0 ?
                <BasicInformation allowEdit={allowEdit} values={values} errors={errors} touched={touched} 
                  setFieldValue={setFieldValue} customers={customers} aircraft={aircraft} aircraftDetails={aircraftDetails} 
                  setAircraftDetails={setAircraftDetails} stations={stations} 
                  setServiceDataOptions={setServiceDataOptions} aircraftEngineers={aircraftEngineers} getCustomerById={getCustomerById} /> 
                : activeStep === 1 ?
                <SelectService allowEdit={allowEdit} values={values} isClicked={click} setFieldValue={setFieldValue} serviceCategories={serviceCategories} 
                serviceDataOptions={serviceDataOptions} getServiceByCategoryAndAircraftId={getServiceByCategoryAndAircraftId} /> :
                activeStep === 2 ? 
                <TimeSlot values={values} errors={errors} isClicked={click} setFieldValue={setFieldValue} /> :
                activeStep === 3 ?  
                <JobOrderSummary values={values} setFieldValue={() => setFieldValue()} customers={customers} stations={stations} aircraft={aircraft} aircraftDetails={aircraftDetails}
                aircraftEngineers={aircraftEngineers} serviceCategories={serviceCategories} serviceDataOptions={serviceDataOptions} /> : null}
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:'space-between',pt: 4 }}>
                <CancelButton title={"Back"} disabled={activeStep === 0} onClick={() =>handleBack()} />
                <CreateButton type='submit' title={activeStep < (steps.length - 1) ? 'Next':'Submit'}  loading={loading}
                  disabled={id && !allowEdit && activeStep === (steps.length - 1)}
                 onClick={() => {setClick(true);activeStep < (steps.length - 1) ? handleNextBtn(values) && handleNext() : setSubmit(true)}}/>
              </Box>
            </Fragment>
          </Box>
        </Form>)}
     </Formik>}
    </Layout>
  );
}
export default CreatejobOrder;
