const ServiceCategoryRoutes = {
    getServiceCategories: "/admin/serviceCategories",
    // getServiceCategoryByID: "/admin/service/",
    getServiceCategoryById:"/admin/serviceCategoryById/",
    getServiceSubCategories: "/admin/serviceSubCategories",
    getServiceSubCategoryById:"/admin/serviceSubCategoryById",
    getServiceSubCategoryByCategoryId:"/admin/serviceSubCategoryByCategoryId",
    postServiceCategory:"/admin/serviceCategory",
    postServiceSubCategory:"/admin/serviceSubCategory",
    patchServiceCategoryAndSubCategoryById:"/admin/updateCategory/",
    deleteServiceCategoryAndSubCategoryById:"/admin/serviceCategory",
    // Types
    getTypes:"/admin/processTypes",
    // Checklist
    getServiceChecklist: "/admin/checklists",
    getServiceChecklistById:"/admin/checklist/",
    postServiceChecklist:"/admin/checklist",
    patchServiceChecklist:"/admin/checklist/",
    deleteChecklist:"/admin/checklist/",
    // Service
    getServiceListing:"/admin/services",
    getServiceById:"/admin/service/",
    postService:"/admin/service",
    patchService:"/admin/service/",
    patchServiceStatus:"/admin/updateServiceStatus/",
    deleteService:"/admin/service/",
    getServiceByCategoryAndAircraftId:"/admin/serviceByCategoryAndAircraftId/",
    getServicesPriceByTime:"/admin/servicesPriceByTime",
  };
  
  export default ServiceCategoryRoutes