import React from 'react';
import { Typography, Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'
import { HowToRegOutlined } from '@mui/icons-material';

const Payments = ({ total,label,heading }) => {

  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    data: [0,0.5,0,0.2,0.3,0.7,0.2,0.5,0,0.2,0.4,0.1,1,1,0,1,2,3,4,3,2,1], // Replace with your actual data points
  };

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: label,
        fill: 'start',
        backgroundColor: 'rgba(239, 165, 95, 0.4)',
        borderColor: 'white',
        borderCapStyle: 'round',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'white',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(239, 165, 95, 0.4)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: chartData.data,
        
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false, // Hide the x-axis
      },
      y: {
        display: false, // Hide the y-axis
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    elements: {
      line: {
        tension: 0, // Disable line tension for straight lines
      },
    },
  };

  return (
    <Box sx={{width:'100%',height:180,position:'relative',p:0,background: `linear-gradient(45deg, #F66B2E 30%, #EFA55F 90%)`,borderRadius:5,cursor:'pointer'}}>
      <Box sx={{p:4}}>
        <Typography sx={{fontSize:'16px', textTransform:'uppercase', fontWeight:'bold',color:'white'}}>{heading}</Typography>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
        <Typography variant="h2" sx={{ fontWeight: "bold", color: 'white'}}>{total}</Typography>
        <HowToRegOutlined sx={{fontSize:65,color:'white'}} />
        </Box>
      </Box>
      <Box sx={{position:'absolute',bottom:0,width:'100%'}}>
        <Line data={data} options={options}  />
      </Box>
    </Box>
  );
};

export default Payments;
