import { Box,  Typography } from '@mui/material';
import ImageViewer from 'app/components/ImageViewer/ImageViewer';
import colors from 'app/styles/colors';
import React, { Fragment, useState, } from 'react'


function UploadImage({ uploadFile, setUploadFile, data,showUploadImage }) {
    const [isImage, setImage] = useState(false);
    return (
        <Fragment>
            {!showUploadImage ? (
            <>
            {data?.length > 0 && 
                <>
                <Typography sx={{color:colors.primary, fontWeight:600, my:2, fontSize:22}}>Images:</Typography>
                <Box sx={{display:'flex',flexWrap:'wrap',my:2,gap:2}}>
                    {data?.map((file,index) => (
                    <Box key={index}  p={1} sx={{backgroundColor:colors.primaryLight,position:"relative", cursor:'pointer'}}
                        onClick={() => setImage(true)}>
                        <img
                            src={file?.image}
                            alt={`Pic-${index+1}`}
                            style={{ objectFit: 'contain', width: '105px', height: '90px' }}
                            key={index}/>
                        </Box>
                    ))}
                </Box>
                </>}
            </>
            ) : (
            <>
            </>
            )}
            {isImage &&
            <ImageViewer openImageViewer={isImage} setImageViewer={setImage} ImageList={data?.length ? data : []} />}
        </Fragment>
    );
    };

export default UploadImage