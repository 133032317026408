import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Box, FormControl, FormLabel, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, styled, tableCellClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AircraftServices from 'app/apis/Aircraft/AircraftServices';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import Loader from 'app/components/Loader/Loader';
import StatusChip from 'app/components/StatusChip/StatusChip';
import { ErrorToaster } from 'app/components/Toaster/Toaster';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { Form, Formik } from 'formik';
import JobOrderServices from 'app/apis/JobOrder/JobOrderService';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f4',
    color: '#3F424B',fontSize:16,fontWeight:'bold',textAlign:'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,textAlign:'center'
  },
}));

const useStyles = makeStyles({
  table: {
    "& .MuiTableCell-root": {
      borderRight: '1px solid #00000033'
    }
  }
});

const columns = [
  {id: 'order_number',label: 'Job Order Number',align: 'left',clickAble:true},
  {id: 'start_date',label: 'Date',align: 'left'},
  {id: 'station',label: 'Station',align: 'left'},
  {id: 'order_status',label: 'Status',align: 'left'},
  {id:'payment_status',label:'Payment Status', align:'left'}
];

function ViewFleet() {
  const classes = useStyles();
  const {id,fleetId,name} = useParams()
  const navigate = useNavigate();
  // loader
  const [isLoading, setIsLoading] = useState(false);
  const [aircraftData, setAircraftData] = useState([]);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [aircraftServed, setAircraftServed] = useState([]);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  // api data
  const [jobOrderListById, setJobOrderListById] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(1);
  };

  // *For Getting Aircraft Types
  const getAircraftTypes = async () => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftTypes()
      if (status === true && responseCode === 200) {
        setAircraftTypes(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Aircraft
  const getAircraftServed = async (obj) => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftServed(obj)
      if (status === true && responseCode === 200) {
        setAircraftServed(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }
  // *For Getting Fleet By Id
  const getFleetById = async (obj) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await AircraftServices.getFleetById(obj)
      if (status === true && responseCode === 200) {
        setAircraftData(data);
        getAircraftServed({limit:1000,page:1,aircraft_type_id:data?.aircraft_type_id});
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }
  // JobOrderListByAircraftId
  const getJobOrderListById = async (obj) => {
    try {
      const { status, responseCode, data,count } = await JobOrderServices.getAllJobOrderDetails(obj)
      if (status === true && responseCode === 200) {
        setJobOrderListById(data);
        setCount(count);
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  useEffect(() => {
    getFleetById({id:id,fleetId:fleetId})
    getAircraftTypes();
    getJobOrderListById({customer_id:id,limit:rowsPerPage,page:page});
  }, [id,rowsPerPage, page]);

  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          aircraft_type_id:aircraftData?.aircraft_type_id,
          aircraft_served_id:aircraftData?.aircraft_served_id,
          tail_number:aircraftData?.tail_number,
          msn_number:aircraftData?.msn_number,
        }}
        onSubmit={() => console.log()}>
        {({ values }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center'}}>
            <ArrowBackButton />
            <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px'}}>{name}</Typography>
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Dashboard','Customer','View Aircraft Information']} />
          </Box>
          {isLoading ? <Loader /> :
          <Fragment>
            <Grid container xs={12} md={12} spacing={2} mx={'auto'} sx={{backgroundColor:colors.white, borderRadius: '10px',pr:2,py:4,my:2,
              boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'}}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Type</FormLabel>
                <Select name="aircraft_type_id"  size="medium"
                  disabled={true}  
                  value={values?.aircraft_type_id ? values?.aircraft_type_id : 0}
                  sx={{color:values?.aircraft_type_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Aircraft Type
                  </MenuItem>
                  {aircraftTypes?.length > 0 ? aircraftTypes?.map((aircraftTypeItem,index) => (
                  <MenuItem key={index} value={aircraftTypeItem?.id}>{aircraftTypeItem?.type}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Make</FormLabel>
                <Select name="aircraft_served_id"  size="medium"
                  disabled={true}  
                  value={values?.aircraft_served_id ? values?.aircraft_served_id : 0}
                  sx={{color:values?.aircraft_served_id === '' ? colors.labelColor : ''}} >
                  <MenuItem value={0} disabled sx={{display:'none'}}>
                    Select Aircraft Make
                  </MenuItem>
                  {aircraftServed?.length > 0 ? aircraftServed?.map((aircraftServedItem,index) => (
                  <MenuItem key={index} value={aircraftServedItem?.id}>{aircraftServedItem?.name}</MenuItem>
                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Tail Number</FormLabel>
                <TextField disabled={true} name="tail_number" placeholder="Tail Number" size="medium"
                value={values?.tail_number} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>MSN Number</FormLabel>
                <TextField disabled={true} name="msn_number" placeholder="MSN Number" size="medium"
                value={values?.msn_number} />
                </FormControl>
              </Grid>
            </Grid>
            {/* Previous Job Orders */}
            <Fragment>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px',my:2}}>Aircraft Services Records</Typography>
              </Box>
              {jobOrderListById?.length > 0 ? 
              <Paper sx={{  overflow: 'hidden',scroll:'auto',width:'100%', boxSizing:'border-box',borderRadius:'10px'  }}>
                <TableContainer sx={{ maxHeight: 800,borderRadius:'10px'}}>
                <Table className={classes.table} stickyHeader aria-label="sticky table" >
                    <TableHead >
                      <TableRow >
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{ fontWeight:600 }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobOrderListById?.map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <Fragment key={index}>
                                {(column.id === 'status' || column.id === 'payment_status') ? (
                                <StyledTableCell key={column.id} align={column.align}>
                                  <StatusChip value={value?.toLowerCase() === 'open' ? 'Upcoming' : value ?? 'Pending'} />
                                </StyledTableCell>
                                ) : (
                                <StyledTableCell key={column.id} align={column.align} sx={{cursor: column.clickAble ? 'pointer' : 'default'}} 
                                onClick={() =>  {column.clickAble && navigate(`/view-job-order/${row?.job_order_number}`)}}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </StyledTableCell>)}
                                </Fragment>
                              );
                            })}
                          </TableRow>
                        );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25,50 ,100]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page -1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper> :
              <Box
                display={'flex'}
                p={6.25}
                alignItems={'center'}
                justifyContent={'center'}
              >
              <Typography variant="h5" fontWeight={400} >
                No Service History
              </Typography>
              </Box>}
            </Fragment>
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default ViewFleet