export const camelToSentence = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  
export const snakeToSentence = (text) => {
  return text.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
};

export const hyphenToSentence = (text) => {
  return text.replace(/^-*(.)|-+(.)/g, (s, c, d) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase()
  );
};

export const sentenceToSnake = (text) => {
  return text.replaceAll(' ', '_').toLowerCase();
};

export const sentenceToHyphen = (text) => {
  return text.replace(/\s+/g, '-').toLowerCase();
};

export const numberSARFormat = (number) => {
  return number?.toLocaleString('en-US', { minimumFractionDigits: 2 });
}

export const formatPrice = (inputValue) => {
  // Remove non-digit characters except decimal point
  const numericValue = inputValue?.replace(/[^\d.]/g, '');
  // Format with US commas and decimals
  const parts = numericValue?.split('.');
  parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1]?.slice(0, 2); // Limit decimals to 2 places
  }
  return parts.join('.');
};