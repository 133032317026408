import React, { Fragment, useEffect, useState } from 'react';
import {  Accordion, AccordionDetails, AccordionSummary, Box, Button,FormControl, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Layout from 'app/layout/Layout';
import colors from 'app/styles/colors';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import { Add,DeleteOutline, EditTwoTone, ExpandMore, RemoveRedEyeOutlined } from '@mui/icons-material';
import {  SecondaryButton } from 'app/components/Buttons/Button';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import BreadCrumbs from 'app/components/Breadcrumbs/Breadcrumbs';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';
import ArrowBackButton from 'app/components/Buttons/ArrowBackButton';
import CustomerServices from 'app/apis/Customer/CustomerServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import AircraftServices from 'app/apis/Aircraft/AircraftServices';
import { EntitySelectInput } from 'app/components/Input/EntitySelect';
import { DropZone } from 'app/components/Input/DropZone';
import Loader from 'app/components/Loader/Loader';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'12px', color:colors.danger,margin:"8px 0px"
}));

const createSchema = Yup.object().shape({
  // aircraft_engineer_name: Yup.string().required('Aircraft Engineer is Required'),
  // aircraft_engineer_email: Yup.string().required('Aircraft Engineer Email is Required'),
  name: Yup.string().required('Name is Required'),
  // email:Yup.string().required('Email is Required'),
  customer_type_id: Yup.number().required('Customer Type is Required'),
  commercial_license: Yup.string().required('Commercial License is Required'),
  operational_license: Yup.string().required('Operational License is Required'),
  country_id: Yup.number().required('Country is Required'),
  city_id: Yup.number().required('City is Required'),
  street: Yup.string().required('Street is Required'),
  building: Yup.string().required('Building is Required'),
  main_office_number: Yup.number().required('Office Number is Required'),
  contact_details:Yup.array().of(Yup.object().shape({
    type:Yup.string().required('Type is Required'),
    name:Yup.string().required('Name is Required'),
    number:Yup.number().required('Mobile Number is Required'),
    email:Yup.string().required('Email is Required'),
  })),
  fleets:Yup.array().of(Yup.object().shape({
    aircraft_type_id:Yup.number().required('Aircraft Type is Required'),
    aircraft_served_id:Yup.number().required('Aircraft Model is Required'),
    tail_number:Yup.string().required('Tail Number is Required'),
    msn_number:Yup.string().required('MSN Number is Required'),
  }))
});

function CreateCustomer() {
  const {id,name} = useParams();
  const navigate = useNavigate();
  const [allowEdit, setAllowEdit] = useState(id ? false : true);

  const [optionsLoading, setOptionsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // loader
  const [isLoading, setIsLoading] = useState(false);

  const [customerTypesData, setCustomerTypesData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [aircraftTypesData, setAircraftTypesData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [baseUrl, setBaseUrl] = useState('');
  const [aircraftTypesOptions, setAircraftTypesOptions] = useState([{options:[]}]);

  // handle country and city name
  const handleStateDetails = (id,setFieldValue,lookup) => {
    if(lookup === 'country'){
      const value = countriesData?.filter((e) => e?.id === id);
      if(value?.length > 0){
        setFieldValue(`country_name`, value[0]?.name)
      }
    } 
  }

  // *For Getting Customer Types
  const getCustomerTypes = async () => {
    try {
      const { status, responseCode, data, } = await CustomerServices.getCustomerTypes()
      if (status === true && responseCode === 200) {
        setCustomerTypesData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Countries
  const getCountries = async () => {
    try {
      const { status, responseCode, data} = await CustomerServices.getCountries({limit:10000,page:1})
      if (status === true && responseCode === 200) {
        setCountriesData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Aircraft
  const getAircraftServed = async (obj) => {
    try {
      setOptionsLoading(true);
      const { status, responseCode, data } = await AircraftServices.getAircraftServed(obj?.data)
      if (status === true && responseCode === 200) {
        aircraftTypesOptions[obj?.index] = {options:data}
        setAircraftTypesOptions(() => {return aircraftTypesOptions});
        setOptionsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setOptionsLoading(false);
    }
  }

  // *For Getting Aircraft Types
  const getAircraftTypes = async () => {
    try {
      const { status, responseCode, data } = await AircraftServices.getAircraftTypes()
      if (status === true && responseCode === 200) {
        setAircraftTypesData(data)
      }
    } catch (error) {
      ErrorToaster(error)
    }
  }

  // *For Getting Customers By Id
  const getCustomerById = async (id) => {
    try {
      setIsLoading(true);
      const { status, responseCode, data,imageBaseUrl } = await CustomerServices.getCustomerById(id)
      if (status === true && responseCode === 200) {
        setCustomerData(data);
        for (let customerFleetIndex = 0; customerFleetIndex < data?.fleets.length; customerFleetIndex++) {
          getAircraftServed({data:{limit:1000,page:1,aircraft_type_id:data?.fleets[customerFleetIndex]?.aircraft_type_id},index:customerFleetIndex});
          setBaseUrl(imageBaseUrl);
        }
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  // getting Aircraft Name By Id
  const getAircraftName = (fleetId) => {
    for (let index = 0; index < aircraftTypesData?.length; index++) {
      if (aircraftTypesData[index]?.id === fleetId) {
        return aircraftTypesData[index]?.type;
      }
    }
    return null; // Return null after the loop if no match is found
  };
  // handleSubmit
  const handleSubmitCustomer = async (values) => {
    try {
      setLoading(true)
      const newValues = {...values, contact_details: JSON.stringify(values?.contact_details),fleets:JSON.stringify(values?.fleets),logo:values?.logo[0]}
      const formData = new FormData();
      const keys = Object.keys(newValues)
      for (let index = 0; index < keys.length; index++) {
       if(keys[index]=== 'logo' && newValues?.logo?.preview){
        formData.append(keys[index],newValues[keys[index]])
      } else if(keys[index] !== 'logo' && newValues[keys[index]]){
        formData.append(keys[index],newValues[keys[index]])
      } 
    }
      if(id){
        const { status, responseCode, message } = await CustomerServices.patchCustomerById({id:id,data:formData})
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await CustomerServices.postCustomer(formData)
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if(id){
    getCustomerById(id)
    // if(customerData?.country_id){getCitiesByCountryId({id:customerData?.country_id})}
    }
    getCustomerTypes();
    getCountries();
    getAircraftTypes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id,customerData?.country_id]);

  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          name: id ? customerData?.name : '',
          // email:id ? customerData?.email : '',
          // aircraft_engineer_name: '',
          // aircraft_engineer_email:'',
          customer_type_id:id ? customerData?.customer_type_id : '',
          commercial_license: id ? customerData?.commercial_license : '',
          operational_license: id ? customerData?.operational_license : '',
          country_id: id ? customerData?.country_id : '',
          country_name: id ? customerData?.country_name : '',
          city_id: id ? customerData?.city_id : '',
          city_name: id ? customerData?.city_name : '',
          street: id ? customerData?.street : '',
          building: id ? customerData?.building : '',
          main_office_number: id ? customerData?.main_office_number :  '',
          contact_details:id ? customerData?.contact_details : [{type: 'Admin',name:'', number: '',email:''},{type: 'Legal',name:'', number: '',email:''},
          {type: 'Procurement',name:'', number: '',email:''},{type: 'Operation  ',name:'', number: '',email:''}],
          // aircraft_engineers:id ? customerData?.aircraft_engineers : [{name:'',email:''}],
          fleets:id ? customerData?.fleets : [{aircraft_type_id: '',aircraft_served_id:'',tail_number:'', msn_number: ''}],
          logo:customerData?.logo ? [baseUrl+customerData?.logo] : ''
        }}
        validationSchema={createSchema}
        onSubmit={(values) => {handleSubmitCustomer(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',}}>
            <Box sx={{display:'flex', alignItems:'center', }}>
              <ArrowBackButton />
              <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'50px',textTransform:'capitalize'}}>{id ? `${allowEdit ? 'Edit' : ''} ${name}` : 'Create Customer'}</Typography>
            </Box>
            {id && 
            <SecondaryButton title={"Edit"}  onClick={() => setAllowEdit(true)} endIcon={<EditTwoTone />} />}
          </Box>
          <Box sx={{ml:8}}>
            <BreadCrumbs currentPage={['Customer',`${!id ? 'Create' : allowEdit ? 'Edit' : ''} Customer`]} />
          </Box>
          {isLoading ? <Loader /> : 
          <Fragment>
            {/* Basic Information */}
            <Accordion defaultExpanded sx={{backgroundColor:colors.white, boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2,borderRadius:'10px',positon:'unset'}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{color:colors.primary, fontWeight:500, fontSize:'26px'}}>Basic Information:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} md={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
                    <TextField disabled={!allowEdit} name="name" placeholder="Name" size="medium" value={values?.name}
                    onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
                    {touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Type</FormLabel>
                    <Select name="customer_type_id"  size="medium"
                      disabled={!allowEdit}  
                      value={values?.customer_type_id ? values?.customer_type_id : 0}
                      onChange={(e) => {setFieldValue(`customer_type_id`, e?.target?.value)}}
                      sx={{color:values?.customer_type_id === '' ? colors.labelColor : ''}} >
                      <MenuItem value={0} disabled sx={{display:'none'}}>
                        Select Type
                      </MenuItem>
                      {customerTypesData?.length > 0 ? customerTypesData?.map((customerTypeItem,index) => (
                      <MenuItem key={index} value={customerTypeItem?.id}>{customerTypeItem?.type}</MenuItem>
                    )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                    </Select>
                    {touched.customer_type_id && errors.customer_type_id && <CustomErrorBox>{errors.customer_type_id}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Commercial License</FormLabel>
                    <TextField disabled={!allowEdit} name="commercial_license" placeholder="Commercial License" size="medium" value={values?.commercial_license}
                    onChange={(e) => {setFieldValue(`commercial_license`, e?.target?.value)}} />
                    {touched.commercial_license && errors.commercial_license && <CustomErrorBox>{errors.commercial_license}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Operation License</FormLabel>
                    <TextField disabled={!allowEdit} name="operational_license" placeholder="Operation License" size="medium" value={values?.operational_license}
                    onChange={(e) => {setFieldValue(`operational_license`, e?.target?.value)}} />
                    {touched.operational_license && errors.operational_license && <CustomErrorBox>{errors.operational_license}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {/* Customer Image Upload */}
                    {(allowEdit || values.logo !== '') &&
                    <Fragment>
                      <Typography sx={{color:colors.primary, fontWeight:500, fontSize:'22px',my:2}}>Upload Logo:</Typography>
                      <DropZone
                        isDisable={allowEdit}
                        setUploadFile={(e, value) => {setFieldValue(e, value);}}
                        uploadFile={values.logo ? values.logo : []}/>
                    </Fragment>}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Legal Information */}
            <Accordion sx={{backgroundColor:colors.white, boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2,borderRadius:'10px',positon:'inherit'}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{color:colors.primary, fontWeight:500, fontSize:'26px'}}>Legal Information:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} md={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Country</FormLabel>
                      <Select name="country_id"  size="medium"
                        disabled={!allowEdit}  
                        value={values?.country_id ? values?.country_id : 0}
                        onChange={(e) => {setFieldValue(`country_id`, e?.target?.value);
                        handleStateDetails(e?.target?.value,setFieldValue,'country')}}
                        sx={{color:values?.country_id === '' ? colors.labelColor : ''}} >
                        <MenuItem value={0} disabled sx={{display:'none'}}>
                          Select Country
                        </MenuItem>
                        {countriesData?.length > 0 ? countriesData?.map((countryItem,index) => (
                        <MenuItem key={index} value={countryItem?.id}>{countryItem?.name}</MenuItem>
                      )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                      </Select>
                      {touched.country_id && errors.country_id && <CustomErrorBox>{errors.country_id}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>City</FormLabel>
                      <EntitySelectInput name={"city_id"} countryID={values?.country_id} isDisabled={!allowEdit || values?.country_id === ''} 
                        onChange={(e) => {setFieldValue(`city_id`, e?.value ? e?.value : null);setFieldValue('city_name',e?.label)}} 
                        value={{value:values?.city_id,label:values?.city_name}}
                      />
                      {touched.city_id && errors.city_id && <CustomErrorBox>{errors.city_id}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Street</FormLabel>
                    <TextField disabled={!allowEdit} name="street" placeholder="Street" size="medium" value={values?.street}
                    onChange={(e) => {setFieldValue(`street`, e?.target?.value)}} />
                    {touched.street && errors.street && <CustomErrorBox>{errors.street}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Building</FormLabel>
                    <TextField disabled={!allowEdit} name="building" placeholder="Building" size="medium" value={values?.building}
                    onChange={(e) => {setFieldValue(`building`, e?.target?.value)}} />
                    {touched.building && errors.building && <CustomErrorBox>{errors.building}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Contacts */}
            <Accordion sx={{backgroundColor:colors.white, boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2,borderRadius:'10px',positon:'inherit'}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{color:colors.primary, fontWeight:500, fontSize:'26px'}}>Contact Information:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} md={12} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth >
                    <FormLabel sx={{mb:1.5, color:colors.formLabel, fontWeight:'bold'}}>Main Office</FormLabel>
                    <TextField disabled={!allowEdit} name="main_office_number" placeholder="Phone Number" type='number' size="medium" value={values?.main_office_number}
                    onChange={(e) => {setFieldValue(`main_office_number`, e?.target?.value)}}/>
                    {touched.main_office_number && errors.main_office_number && <CustomErrorBox>{errors.main_office_number}</CustomErrorBox>}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FieldArray
                      name="contact_details"
                      render={arrayHelpers => (
                        <Grid item container>
                          <Grid item xs={12} md={12} textAlign={'right'}>
                          {values?.contact_details?.length > 0 && 
                            <Button
                              disabled={!allowEdit}
                              type="button" variant='outlined'
                              onClick={() => arrayHelpers.push({type: '',name:'', number: '',email:''})}
                              startIcon={<Add />}
                              sx={{textTransform:'capitalize'}}>Add</Button>}
                          </Grid>
                          {values?.contact_details?.map((item, index) => (
                          <Grid item container spacing={2} key={index} alignItems={'flex-start'} my={0.25}>
                            <Grid item xs={12} md={2.4}>
                            {index < 4 ? (
                              <Typography textTransform={'capitalize'} p={0} fontSize={18} mt={{md:4}} justifyContent={'center'} fontWeight={600} color={colors.primary}>{item?.type}{' '}:</Typography>
                            ) : (
                              <FormControl fullWidth>
                                <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Type</FormLabel>
                                <TextField disabled={!allowEdit} name={`contact_details[${index}].type`} placeholder="Type" size="medium" value={values?.contact_details[index].type}
                                onChange={(e) => {setFieldValue(`contact_details[${index}].type`, e?.target?.value)}} />
                                <ErrorMessage
                                  name={`contact_details[${index}].type`}
                                  render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                                />
                              </FormControl>
                              )}
                            </Grid>
                            <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Name</FormLabel>
                                <TextField disabled={!allowEdit} name={`contact_details[${index}].name`} placeholder="Name" size="medium" value={values?.contact_details[index].name}
                                onChange={(e) => {setFieldValue(`contact_details[${index}].name`, e?.target?.value)}} />
                                <ErrorMessage
                                  name={`contact_details[${index}].name`}
                                  render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Mobile Number</FormLabel>
                                <TextField disabled={!allowEdit} name={`contact_details[${index}].number`} type='number' placeholder="Mobile Number" size="medium" 
                                  value={values?.contact_details[index].number} onChange={(e) => {setFieldValue(`contact_details[${index}].number`, e?.target?.value)}} />
                                <ErrorMessage
                                  name={`contact_details[${index}].number`}
                                  render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                              <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Email</FormLabel>
                              <TextField disabled={!allowEdit} name={`contact_details[${index}].email`} placeholder="Email" size="medium" value={values?.contact_details[index].email}
                              onChange={(e) => {setFieldValue(`contact_details[${index}].email`, e?.target?.value)}} />
                              <ErrorMessage
                                name={`contact_details[${index}].email`}
                                render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                              />
                              </FormControl>
                            </Grid>
                            {index > 3 && 
                            <Grid item xs={12} md={0.5}>
                            <DeleteOutline onClick={() => {allowEdit && arrayHelpers.remove(index)}}
                              sx={{color:colors.danger,backgroundColor: colors.dawnPink,p:1,borderRadius: '5px',mt:5,cursor:'pointer' }} />
                            </Grid>}
                          </Grid>
                          ))}
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Fleet */}
            <Accordion sx={{backgroundColor:colors.white, boxShadow:'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;',my:2,borderRadius:'10px',positon:'inherit'}}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{color:colors.primary, fontWeight:500, fontSize:'26px'}}>Fleet Information:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container xs={12} md={12} spacing={2}>
                  <Grid item xs={12} md={12} mb={2}>
                    <FieldArray
                      name="fleets"
                      render={arrayHelpers => (
                      <Grid item container my={2} xs={12} md={12}>
                        <Grid item xs={12} md={12}>
                          <Box sx={{display:'flex', alignItems:'center', justifyContent:'end'}}>
                            {values?.fleets?.length > 0 && 
                            <Button disabled={!allowEdit}
                              type="button" variant='outlined'
                              onClick={() => arrayHelpers.push({ aircraft_type_id: '', aircraft_served_id:'', tail_number: '', msn_number: '' })}
                              startIcon={<Add />} sx={{textTransform:'capitalize'}}>Add
                            </Button>}
                          </Box>
                        </Grid>
                        {values?.fleets?.map((item, index) => (
                        <Grid item container spacing={2} key={index} alignItems={'flex-start'} my={0.25}>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                              <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Type</FormLabel>
                              <Select
                                size='medium' disabled={!allowEdit}
                                name={`fleets[${index}].aircraft_type_id`}
                                value={values?.fleets[index]?.aircraft_type_id ? values.fleets[index]?.aircraft_type_id : 0}
                                onChange={(e) => {setFieldValue(`fleets[${index}].aircraft_type_id`, e?.target?.value);
                                getAircraftServed({data:{limit:1000,page:1,aircraft_type_id:+e?.target?.value},index:index});
                                }}
                                sx={{color:values.fleets[index]?.aircraft_type_id === '' ? colors.labelColor : ''}}
                              >
                              <MenuItem value={0} disabled sx={{display:'none'}}>
                                Select Aircraft Type
                              </MenuItem>
                                {aircraftTypesData?.length > 0 ? aircraftTypesData?.map((aircraftTypeItem,index) => (
                                  <MenuItem key={index} value={aircraftTypeItem?.id} >{aircraftTypeItem?.type}</MenuItem>
                                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                              </Select>
                              <ErrorMessage
                                name={`fleets[${index}].aircraft_type_id`}
                                render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth size="small">
                              <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Aircraft Model</FormLabel>
                              <Select
                                size='medium' disabled={!allowEdit || values?.fleets[index]?.aircraft_type_id === ''}
                                name={`fleets[${index}].aircraft_served_id`}
                                value={values?.fleets[index]?.aircraft_served_id ? values.fleets[index]?.aircraft_served_id : 0}
                                onChange={(e) => {setFieldValue(`fleets[${index}].aircraft_served_id`, e?.target?.value);
                                }}
                                sx={{color:values?.fleets[index]?.aircraft_served_id === '' ? colors.labelColor : ''}}
                                onLoad={optionsLoading}
                                // IconComponent={optionsLoading? <CircularProgress size={'sm'} /> : null}
                                >
                              <MenuItem value={0} disabled sx={{display:'none'}}>
                                Select Aircraft Model
                              </MenuItem>
                              {aircraftTypesOptions[index]?.options?.length > 0 ? aircraftTypesOptions[index]?.options?.map((aircraftItem,index) => (
                                <MenuItem key={index} value={aircraftItem?.id}>{aircraftItem?.name}</MenuItem>
                                )) : (<MenuItem value={''} disabled>No Option</MenuItem>) }
                              </Select>
                              <ErrorMessage
                                name={`fleets[${index}].aircraft_served_id`}
                                render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth size="small">
                              <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>Tail Number</FormLabel>
                              <TextField disabled={!allowEdit} name={`fleets[${index}].tail_number`} placeholder="Tail Number" size="medium"
                              value={values?.fleets[index]?.tail_number}
                              onChange={(e) => {setFieldValue(`fleets[${index}].tail_number`, e?.target?.value)}} />
                              <ErrorMessage
                                name={`fleets[${index}].tail_number`}
                                render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={2.5}>
                            <FormControl fullWidth size="small">
                              <FormLabel sx={{my:1, color:colors.formLabel, fontWeight:'bold'}}>MSN Number</FormLabel>
                              <TextField disabled={!allowEdit} name={`fleets[${index}].msn_number`} placeholder="MSN Number" size="medium"
                              value={values?.fleets[index]?.msn_number}
                              onChange={(e) => {setFieldValue(`fleets[${index}].msn_number`, e?.target?.value)}} />
                              <ErrorMessage
                                name={`fleets[${index}].msn_number`}
                                render={msg => <CustomErrorBox>{msg}</CustomErrorBox>}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={1.5}>
                            <Box gap={2} display={'flex'} mt={5.5}>
                              {values?.fleets?.length > 1 && <DeleteOutline onClick={() => {allowEdit && arrayHelpers.remove(index)}}
                              sx={{color:colors.danger,backgroundColor: colors.dawnPink,p:1,borderRadius: '5px',cursor:'pointer' }} />}
                              {item?.aircraft_type_id && id && <RemoveRedEyeOutlined onClick={() => navigate(`/customer-management/${id}/fleet/${getAircraftName(item?.aircraft_type_id)}/${item?.id}`)}
                              sx={{color:colors.primary,backgroundColor: colors.primaryLight,p:1,borderRadius: '5px',cursor:'pointer' }} />}
                            </Box>
                          </Grid>
                        </Grid>
                        ))}
                      </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {/* Create/Update Section */}
            {allowEdit && 
            <Box textAlign={'right'} my={2}>
              <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
              <CreateButton title={id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
            </Box>}
          </Fragment>}
        </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default CreateCustomer