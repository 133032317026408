const colors = {
  primary: "#242F48",
  primaryLight: "#ECF0FA",
  primaryDark: "#091e47",
  secondary: "#CCCCCC",
  formLabel: "#AAAAAA",
  secondaryDark: "#2f2e41c7",
  accent:"#FF6600",
  accentLight:'#fadcc8',
  artyClickAmber: "#ffc107",
  eggSour: "#eddaa95e",
  dairyCream: "#eddaa95e",
  darkMintGreen: "rgb(0, 200, 83)",
  grapeFruit: "rgb(216, 67, 21)",
  dawnPink: "#FBE9E7",
  tertiary: "#161624",
  textPrimary: "#262626",
  textSecondary: "#65778F",
  waterBlue: "#03989E",
  waterBlueLight: "#17c7cf",
  textLight: "#78829A",
  candleLight: "#FFD824",
  white: "#ffffff",
  whiteSmoke: "#f6f6f6",
  mercury: "#f5f9fc",
  black: "#222222",
  lightGray: "#e3e8e885",
  danger: "#FF2121",
  ebonyClay: "#252340",
  gunMetal: "#263238",
  success: "#008035",
  failed:'#DC4949',
  pending:'#DCA649',
  successLight:"#d2f2bd",
  cloud: "#C4C4C4",
  labelColor:'#727171',
  cloudBurst:'#242F48',
  springGreen:'#008035',
  barPrimary:'#285CF7',
  barSecondary:'#F7557A',
  pendingDark:'#c8eeff',
  pendingLight:'#eff9fe',
  pendingHover:'#dff5fe',
  confirmedDark:'#a8efd8',
  confirmedLight:'#effcf7',
  confirmedHover:'#e0f7f0',
  canceledDark:'#ffd4a6',
  canceledLight:'#fef4ed',
  canceledHover:'#ffeddc',
  unpaidDark:'#fdb8bc',
  unpaidLight:'#feeeef',
  unpaidHover:'#fedee0'
};

export default colors;
