const MaterialsEquipmentsRoutes = {
  getMaterialsEquipmentsTypes: "/admin/equipmentTypes",
  getMaterialsEquipmentTypesById:"/admin/equipmentsByEquipmentTypeId/",
  getServiceEquipments:"/admin/serviceEquipments", 
  deleteMaterialsEquipmentsById:"/admin/serviceEquipment/",
  getRampStatuses:"/admin/rampStatuses",
  postMaterialsEquipments:"/admin/serviceEquipment",
  patchMaterialsEquipmentsById:"/admin/serviceEquipment/",
  getStations:"/admin/stations",
  getServiceEquipmentById:"/admin/serviceEquipment/",
  patchServiceEquipmentStatus:"/admin/updateServiceEquipmentStatus/"
}
export default MaterialsEquipmentsRoutes