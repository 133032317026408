import CreateCustomer from "app/views/CustomerManagement/CreateCustomer";
import CustomerManagement from "app/views/CustomerManagement/CustomerManagement";
import EquipmentMaterials from "app/views/EquipmentMaterials/EquipmentMaterials";
import ManageIndividualEquipment from "app/views/EquipmentMaterials/ManageIndividualEquipment";
import RequestMaintenance from "app/views/EquipmentMaterials/RequestMaintenance";
import Invoices from "app/views/Invoices/Invoices";
import CreateListing from "app/views/JobOrder/CreateListing";
import ViewJobOrder from "app/views/JobOrder/ViewJobOrder";
import ViewListing from "app/views/JobOrder/ViewListing";
import AddCheckList from "app/views/Services/AddCheckList";
import CategoryListing from "app/views/Services/CategoryListing";
import CheckList from "app/views/Services/CheckList";
import CreateService from "app/views/Services/CreateService";
import ServiceListing from "app/views/Services/ServiceListing";
import SubCategoryListing from "app/views/Services/SubCategoryListing";
import CreateEmployee from "app/views/Team&Employee/CreateEmployee";
import CreateIndividualTeam from "app/views/Team&Employee/CreateIndividualTeam";
import IndividualTeam from "app/views/Team&Employee/IndividualTeam";
import ManageEmployee from "app/views/Team&Employee/ManageEmployee";
import ManageTeam from "app/views/Team&Employee/ManageTeam";
import SingleInvoice from '../views/Invoices/SingleInvoice';
import Dashboard from "app/views/Dashboard/Dashboard";
import MyProfile from "app/views/MyProfile/MyProfile";
import UserManagement from "app/views/UserManagement/UserManagement";
import CreateUser from "app/views/UserManagement/CreateUser";
import CreatejobOrder from "app/views/JobOrder/CreateJobOrder";
import ViewFleet from "app/views/CustomerManagement/ViewFleet";


const AdminRoutes = [
  {
    path: "/",
    component: <Dashboard />,
  },
  {
    path: "/create-job-order",
    component: <CreatejobOrder />,
  },
  {
    path: "/view-job-order",
    component: <ViewListing />,
  },
  {
    path: "/view-job-order/status/:status",
    component: <ViewListing />,
  },
  {
    path: "/view-job-order/:id",
    component: <ViewJobOrder />,
  },
  {
    path: "/edit-job-order/:order_number/:id",
    component: <CreatejobOrder />,
  },
  {
    path: "/view-invoices",
    component: <Invoices />,
  },
  {
    path: "/view-job-order/:id/:invoiceNumber",
    component: <SingleInvoice />,
  },
  {
    path: "/view-invoices/:id/:invoiceNumber",
    component: <SingleInvoice />,
  },
  {
    path: "/customer-management",
    component: <CustomerManagement />,
  },
  {
    path: "/create-customer",
    component: <CreateCustomer />,
  },
  {
    path: "/customer-management/edit/:name/:id",
    component: <CreateCustomer />,
  },
  {
    path: "/customer-management/:id/fleet/:name/:fleetId",
    component: <ViewFleet />,
  },
  {
    path: "/user-management",
    component: <UserManagement />,
  },
  {
    path: "/create-user",
    component: <CreateUser />,
  },
  {
    path: "/user-management/edit/:name/:id",
    component: <CreateUser />,
  },
  {
    path: "/view-services",
    component: <ServiceListing />,
  },
  {
    path: "/create-service/*",
    component: <CreateService />,
  },
  {
    path: "/edit-service/:service/:id/*",
    component: <CreateService />,
  },
  {
    path: "/view-category/*",
    component: <CategoryListing />,
  },
  {
    path: "/view-sub-category/*",
    component: <SubCategoryListing />,
  },
  {
    path: "/check-list",
    component: <CheckList />,
  },
  {
    path: "/check-list/add",
    component: <AddCheckList />,
  },
  {
    path: "/check-list/edit/:name/:id",
    component: <AddCheckList />,
  },
  {
    path: "/manage-team/*",
    component: <ManageTeam />,
  },
  {
    path: "/manage-team/:team/:id",
    component: <IndividualTeam />,
  },
  {
    path: "/manage-team/add",
    component: <CreateIndividualTeam />,
  },
  {
    path: "/manage-team/:team/:id/edit",
    component: <CreateIndividualTeam />,
  },
  {
    path: "/manage-employee",
    component: <ManageEmployee />,
  },
  {
    path: "/manage-employee/add",
    component: <CreateEmployee />,
  },
  {
    path: "/manage-employee/edit/:name/:id",
    component: <CreateEmployee />,
  },
  {
    path: "/equipment&materials",
    component: <EquipmentMaterials />,
  },
  {
    path: "/equipment&materials/add",
    component: <ManageIndividualEquipment />,
  },
  {
    path: "/equipment&materials/:type/edit/:id",
    component: <ManageIndividualEquipment />,
  },
  {
    path: "/equipment&materials/request-maintenance/:id",
    component: <RequestMaintenance />,
  },
  {
    path: "/equipment&materials/request-maintenance/:id/:status",
    component: <RequestMaintenance />,
  },
  {
    path: "/my-profile",
    component: <MyProfile />,
  },
];

export default AdminRoutes