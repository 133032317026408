import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import colors from "app/styles/colors";
import Layout from "app/layout/Layout";
import { useNavigate } from "react-router-dom";
import UpcomingProjectCards from "app/components/Cards/UpcomingProjectCards";
import PreviousJobOrders from "app/components/Cards/PreviousJobOrders";
import PopularServices from "app/components/Cards/PopularServices";
import Payments from "app/components/Cards/Payments";
import DashboardServices from "app/apis/Dashboard/DashboardServices";
import { ErrorToaster } from "app/components/Toaster/Toaster";
import Loader from "app/components/Loader/Loader";
import { PrimaryButton } from "app/components/Buttons/Button";
import { Add } from "@mui/icons-material";

// *import components

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const navigate = useNavigate();
  const [stats, setStats] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Chart  Object
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Growth",
        font: {
          size: 20
        }
      },
    },
  };

  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const data = {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: stats?.totalCompletedJobOrders,
        backgroundColor: colors.barPrimary,
      },
      {
        label: "Total Completed Projects",
        data: stats?.totalRevenue,
        backgroundColor: colors.barSecondary,
      },
    ],
  };

  // *For Get Dashboard Stats
  const getDashboardStats = async () => {
    try {
      setIsLoading(true);
      const { status, responseCode, data } = await DashboardServices.getDashboardStats()
      if (status === true && responseCode === 200) {
        setStats(data);
        setIsLoading(false);
      }
    } catch (error) {
      ErrorToaster(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getDashboardStats()
  }, []);

  return (
    <Layout>
      <Fragment>
        {isLoading ? <Loader /> :
        <Grid container gap={2}
          sx={{backgroundColor: colors.lightGray,borderRadius: "12px",p: 3,width:'100%'}}>
          <Grid item xs={12} md={12}>
            {/* <Typography sx={{color:colors.primary,fontWeight:'bold', fontSize:'30px'}}>Hi, welcome back!</Typography>
            <Typography sx={{color:colors.textLight}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography> */}
            <Button startIcon={<Add />} onClick={() => navigate('/create-job-order')}
              sx={{backgroundColor:colors.springGreen,color:'white',py:1,borderRadius:'8px',
              fontSize:16,"&:hover":{backgroundColor:'#064d25'},float:'right'}} >Create Job Order</Button>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} onClick={() => navigate('/view-job-order/status/open')}>
              <UpcomingProjectCards total={stats?.upcomingJobOrders||0} heading={'Upcoming Job Orders'} label={'Projects'} />
            </Grid>
            <Grid item xs={12} md={3} onClick={() => navigate('/view-job-order/status/closed')}>
              <PreviousJobOrders total={stats?.previousJobOrders||0} heading={'Previous Job Orders'} label={'Previous Jobs'} />
            </Grid>
            <Grid  item xs={12} md={3} onClick={() => navigate('/view-job-order/status/pending')}>
              <PopularServices total={stats?.pendingJobOrders||0} heading={'Pending Job Orders'} label={'Pending Jobs'} />
            </Grid>
            <Grid  item xs={12} md={3}>
              <Payments total={stats?.outstandingInvoices||0} heading={'Outstanding Payments'} label={'Payments'} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} sx={{backgroundColor: colors.white, my: 2,p: 2,boxSizing:'border-box',borderRadius: "12px",height: 430,
          width:{xs:'660px',md:'700px'},}}>
            <Bar options={options} data={data}  />
          </Grid>
        </Grid>}
      </Fragment>
    </Layout>
  );
}

export default Dashboard;
