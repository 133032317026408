import { get, post, patch, deleted } from 'app/apis';
import UserRoutes from './User.Routes';

const UserServices = {
  getUsers: async (obj) => {
    const data = await get(UserRoutes.getUsers+`?limit=${obj?.limit}&page=${obj?.page}${obj?.status ? `&status=${obj?.status}` : ''}`);
    return data;
  },
  getUserById: async (id) => {
    const data = await get(UserRoutes.getUserById+`${id}`);
    return data;
  },
  getUserTypes: async () => {
    const data = await get(UserRoutes.getUserTypes);
    return data;
  },
  postUser: async (obj) => {
    const data = await post(UserRoutes.postUser,obj);
    return data;
  },
  patchUserById: async (obj) => {
    const data = await patch(UserRoutes.patchUserById+`${obj.id}`,obj.data);
    return data;
  },
  deleteUserById: async (id) => {
    const data = await deleted(UserRoutes.deleteUserById+`${id}`);
    return data;
  },
  patchUserStatus: async (obj) => {
    const data = await patch(UserRoutes.patchUserStatus+`${obj.id}`,obj.data);
    return data;
  },
}

export default UserServices