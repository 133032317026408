import { Box, Checkbox, FormControl, FormControlLabel, FormGroup,  Stack, TextField, Typography, styled } from '@mui/material';
import colors from 'app/styles/colors';
import React, { useState } from 'react'

//import modules 👇
import 'react-modern-drawer/dist/index.css'
import UploadImage from './UploadImage';
import ImageViewer from 'app/components/ImageViewer/ImageViewer';
import SubTaskList from './SubTaskList';

const CustomImageTag = styled('img')(() => ({
  objectFit: 'contain', width: '115px', height: '115px',border:`2px solid ${colors?.primaryLight}`,borderRadius:5,padding:'15px'
}));

function PostOrderWorkFlow({checkList}) {
  const [file, setFile] = useState([]);
  const [isSupervisorImage, setIsSupervisorImage] = useState(false);
  const [isAircraftImage, setIsAircraftImage] = useState(false);

  return (
  <Stack>
    <Box my={2}>
      {/* Sub Task List */}
      {checkList?.checkListType?.length > 0 ? 
        <SubTaskList data={checkList?.checkListType} /> : 
        <Typography color={colors.secondaryDark} textAlign={'center'} fontWeight={600}>No Check Points</Typography>}
      {/* End */}
      {/* <TextField fullWidth multiline minRows={3} name="closing_notes" placeholder="Closing Notes" size="small" sx={{my:2}} 
        defaultValue={checkList?.comments} disabled/> */}
      <UploadImage uploadFile={file} setUploadFile={setFile} data={checkList && checkList?.images?.length > 0 ? checkList?.images : []}
        showUploadImage={checkList?.is_after_checklist_done !== 1 && checkList?.is_before_checklist_done === 1} />
      <Box sx={{mb:'150px'}}>
        <Typography sx={{color:colors.primary, fontSize:20, fontWeight:"bold"}}>Signatories:</Typography>
        <FormControl sx={{mb:2}} >
          <FormGroup sx={{display:'flex', alignItems: 'center', justifyContent:'space-between', flexDirection:"row"}}>
            <FormControlLabel control={<Checkbox 
            defaultChecked={checkList?.signed_by_supervisor !== null && checkList?.signed_by_supervisor !== ""}
            disabled />} 
            label={<Typography fontSize={17} color={colors.secondaryDark}>Operation Supervisor</Typography>}  />
            <FormControlLabel control={<Checkbox  
            defaultChecked={checkList?.signed_by_aircraft_engineer !== null && checkList?.signed_by_aircraft_engineer !== ""}
            disabled/>} 
            label={<Typography fontSize={17} color={colors.secondaryDark}>Aircraft Engineer</Typography>}  />
          </FormGroup>
        </FormControl>
        {(checkList?.signed_by_supervisor || checkList?.signed_by_aircraft_engineer) && <Box sx={{display:'flex', alignItems:'center', mb:8}}>
          {checkList?.signed_by_supervisor && <CustomImageTag src={checkList?.signed_by_supervisor} alt="Supervisor Image" style={{marginRight:'90px'}}
          onClick={() => setIsSupervisorImage(true)} />}
          {checkList?.signed_by_aircraft_engineer && <CustomImageTag src={checkList?.signed_by_aircraft_engineer} alt="Aircraft Image" 
            onClick={() => setIsAircraftImage(true)}
          />}
        </Box>}
      </Box>
    </Box>
    {isSupervisorImage &&
    <ImageViewer openImageViewer={isSupervisorImage} setImageViewer={setIsSupervisorImage} ImageList={checkList?.signed_by_supervisor ? [{image:checkList?.signed_by_supervisor}] : []} />}
    {isAircraftImage &&
    <ImageViewer openImageViewer={isAircraftImage} setImageViewer={setIsAircraftImage} ImageList={checkList?.signed_by_aircraft_engineer ? [{image:checkList?.signed_by_aircraft_engineer}] : []} />}
  </Stack>
  );
}
 
export default PostOrderWorkFlow