import React from 'react';
import Lottie from 'react-lottie';
import animationData from './Loader.json';
import { Box } from '@mui/material';

const Loader = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box sx={{flex:1,display:'flex',alignItems:'center'}}  minHeight={'35dvh'} {...props}>
      <Lottie options={defaultOptions} height={120} width={120} />
    </Box>
  );
};
export default Loader 


export const SmLoader = (minHeight) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Box my={4} minHeight={minHeight ? minHeight : '25vh'} alignContent={'center'}>
      <Lottie options={defaultOptions} height={80} width={80} />
    </Box>
  );
};
