import React, { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material'
import { Box, FormControl, FormLabel, Modal, TextField, Typography, styled } from '@mui/material'
import ServiceCategoryServices from 'app/apis/ServiceCategory/ServiceCategoryServices';
import { ErrorToaster, SuccessToaster } from 'app/components/Toaster/Toaster';
import colors from 'app/styles/colors';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CancelButton, CreateButton } from 'app/components/Buttons/ActionButtons';

const CustomErrorBox = styled('div')(() => ({
  fontSize:'14px', color:colors.danger,margin:"10px 4px"
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 555,
  backgroundColor: 'background.paper',
  boxShadow: 24,borderRadius:2,
  p: 2,
};

const categorySchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required"),
});


function AddCategory() {
  const navigate = useNavigate();
  const {id} = useParams();
	const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});

// Getting SubService Data for Update
const getServiceCategoryById = async (id) => {
  try {
    const { status, responseCode, data } = await ServiceCategoryServices.getServiceCategoryByID(id)
    if (status === true && responseCode === 200) {
      setCategoryData(data)
    }
  } catch (error) {
    ErrorToaster(error)
  }
}

  const handleSubmitCategory = async (values) => {
    try {
      setLoading(true)
      if(id){
        const { status, responseCode, message } = await ServiceCategoryServices.patchServiceCategoryAndSubCategoryById({id:id,data:{name:values?.name,status:categoryData?.status}})
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
      else {
        const { status, responseCode, message } = await ServiceCategoryServices.postServiceCategory(values)
        if (status === true && responseCode === 200) {
          SuccessToaster(message);
          navigate(-1)
        }
      }
    } catch (error) {
      ErrorToaster(error)
    }
    setLoading(false)
  }

useEffect(() => {
  if(id){
    getServiceCategoryById(id)
  }
}, [id])


  return (
    <Modal open={true}>
    <Box sx={style}>
		<Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
    <Typography sx={{color:colors.primary, fontWeight:'bold', fontSize:'30px'}}>
      {id ? `Edit Service Category` : 'Add New Service Category'} 
			</Typography>
			<Close sx={{cursor:'pointer'}} onClick={() => navigate(-1)}/>
			</Box>
			<Formik
        enableReinitialize
        initialValues={{name:id ? categoryData?.name : ''}}
        validationSchema={categorySchema}
      	onSubmit={values => {handleSubmitCategory(values)}}>
        {({ errors, touched, values,setFieldValue }) => (
        <Form>
					<FormControl fullWidth>
						<FormLabel sx={{my:2, color:colors.formLabel, fontWeight:'bold'}}>Category Name</FormLabel>
						<TextField name="name" placeholder='Category Name' size="medium" value={values?.name} onChange={(e) => {setFieldValue(`name`, e?.target?.value)}} />
						{touched.name && errors.name && <CustomErrorBox>{errors.name}</CustomErrorBox>}
					</FormControl>
					<Box sx={{textAlign:'right',my:2}}>
            <CancelButton title={"Cancel"}  onClick={() => navigate(-1)} />
            <CreateButton title={ id ? "Update" : "Create"} type="submit" loading={loading} sxstyle={{ml:2}}/>
					</Box>
				</Form>
				)}
				</Formik>
    </Box>
    </Modal>
  )
}

export default AddCategory