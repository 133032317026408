import { Chip } from '@mui/material'
import colors from 'app/styles/colors'
import React from 'react'

function StatusChip({value=''}) {
  const handleColor = (label) => {
    if(['active','open','paid','confirmed','upcoming']?.some((e) => label === e)){
      return {darkColor:colors.confirmedDark,lightColor:colors.confirmedLight,hoverColor:colors.confirmedHover}
    } else if(label === 'pending'){
      return {darkColor:colors.pendingDark,lightColor:colors.pendingLight,hoverColor:colors.pendingHover}
    } else if(label === 'in active' || label === 'canceled' || label === 'expired') {
      return {darkColor:colors.canceledDark,lightColor:colors.canceledLight,hoverColor:colors.canceledHover}
    } else {return {darkColor:colors.unpaidDark,lightColor:colors.unpaidLight,hoverColor:colors.unpaidHover}}
  }

  const colorDetails = handleColor(value?.toLowerCase());
  return (
    <Chip label={value} sx={{cursor:'pointer',textTransform:'capitalize', minWidth:'100px', 
    color:'#7f7e7e',backgroundColor:colorDetails?.lightColor,border:`2px solid ${colorDetails?.darkColor}` ,fontWeight:600,'&:hover':{
      backgroundColor:colorDetails?.hoverColor
    }}} />
  )
}

export default StatusChip