import { get, post, patch, deleted } from 'app/apis';
import EmployeesRoutes from './Employees.Routes';

const EmployeeServices = {
  getEmployees: async (obj) => {
    const data = await get(EmployeesRoutes.getEmployees+`?limit=${obj?.limit}&page=${obj?.page}${obj?.type ? `&type=${obj?.type}` : ''}`);
    return data;
  },
  getEmployeeById: async (id) => {
    const data = await get(EmployeesRoutes.getEmployeeById+`${id}`);
    return data;
  },
  getEmployeeTypes: async () => {
    const data = await get(EmployeesRoutes.getEmployeeTypes);
    return data;
  },
  deleteEmployeeById: async (id) => {
    const data = await deleted(EmployeesRoutes.deleteEmployeeById+`${id}`);
    return data;
  },
  postEmployee: async (obj) => {
    const data = await post(EmployeesRoutes.postEmployee,obj);
    return data;
  },
  patchEmployeeById: async (obj) => {
    const data = await patch(EmployeesRoutes.patchEmployeeById+`${obj.id}`,obj.data);
    return data;
  },
  patchEmployeeStatus: async (obj) => {
    const data = await patch(EmployeesRoutes.patchEmployeeStatus+`${obj.id}`,obj.data);
    return data;
  },
}

export default EmployeeServices