import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const setCookie = (cookieName,data) => {
  cookies.set(cookieName,data,{path:'/',secure:true,sameSite:'strict'})
}

export const getCookie = (cookieName) => {
  return cookies.get(cookieName)
}

export const removeCookie = (cookieName) => {
  return cookies.remove(cookieName,{path:'/',secure:true,sameSite:'strict'})
}